import React from 'react';
import { AdaptiveMetrics } from '../../models/AdaptiveState';
import { Brain, TrendingUp, Clock, Target } from 'lucide-react';
import { DashboardCard } from '../ui/dashboard-card';

interface AdaptiveInsightsCardProps {
  adaptiveLearning?: AdaptiveMetrics;
}

const AdaptiveInsightsCard: React.FC<AdaptiveInsightsCardProps> = ({ adaptiveLearning }) => {
  if (!adaptiveLearning) return null;

  const { overallSuccessRate, weeklyActivityStreak, studyTimeThisWeek, mastery, currentStreak, categoryPerformance, recentPerformance, estimatedExamReadiness } = adaptiveLearning;

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {/* Overall Performance Card */}
      <DashboardCard icon={Brain} title="Learning Progress">
        <div className="grid grid-cols-2 gap-4">
          <div className="text-center">
            <p className="text-3xl font-bold text-blue-600 dark:text-blue-400">
              {overallSuccessRate.toFixed(0)}%
            </p>
            <p className="text-sm text-gray-600 dark:text-gray-300">Success Rate</p>
          </div>
          <div className="text-center">
            <p className="text-3xl font-bold text-green-600 dark:text-green-400">
              {estimatedExamReadiness}%
            </p>
            <p className="text-sm text-gray-600 dark:text-gray-300">Exam Readiness</p>
          </div>
        </div>
        
        {/* Mastery Breakdown */}
        <div className="mt-4">
          <h4 className="font-semibold mb-2 dark:text-gray-100">Category Mastery</h4>
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <span className="dark:text-gray-200">Advanced</span>
              <span className="text-green-500 dark:text-green-400">{mastery.advanced.length}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="dark:text-gray-200">Intermediate</span>
              <span className="text-yellow-500 dark:text-yellow-400">{mastery.intermediate.length}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="dark:text-gray-200">Beginner</span>
              <span className="text-red-500 dark:text-red-400">{mastery.beginner.length}</span>
            </div>
          </div>
        </div>
      </DashboardCard>

      {/* Recent Activity Card */}
      <DashboardCard icon={TrendingUp} title="Recent Performance">
        <div className="space-y-4">
          {recentPerformance.slice(0, 3).map((perf, idx) => (
            <div key={idx} className="flex justify-between items-center">
              <div>
                <p className="font-semibold dark:text-gray-100">{perf.category}</p>
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  {new Date(perf.date).toLocaleDateString()}
                </p>
              </div>
              <div className="text-right">
                <p className="font-bold text-blue-600 dark:text-blue-400">
                  {perf.score.toFixed(1)}%
                </p>
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  {perf.questionsAttempted} questions
                </p>
              </div>
            </div>
          ))}
        </div>
      </DashboardCard>

      {/* Study Habits Card */}
      <DashboardCard icon={Clock} title="Study Patterns">
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <span className="dark:text-gray-200">Weekly Streak</span>
            <span className="font-bold dark:text-gray-100">{weeklyActivityStreak} weeks</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="dark:text-gray-200">Study Time This Week</span>
            <span className="font-bold dark:text-gray-100">{(studyTimeThisWeek / 3600).toFixed(1)} hours</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="dark:text-gray-200">Current Streak</span>
            <span className="font-bold text-green-500 dark:text-green-400">{currentStreak} days</span>
          </div>
        </div>
      </DashboardCard>

      {/* Focus Areas Card */}
      <DashboardCard icon={Target} title="Recommended Focus">
        <div className="space-y-4">
          {Object.entries(categoryPerformance).length > 0 ? (
            <>
              <div>
                <h4 className="font-semibold mb-2 dark:text-gray-100">Weak Categories</h4>
                <ul className="list-disc list-inside text-sm">
                  {Object.entries(categoryPerformance).slice(0, 3).map(([key, category]) => (
                    <li key={key} className="text-red-500 dark:text-red-400">{category.successRate}</li>
                  ))}
                </ul>
              </div>
              <div>
                <h4 className="font-semibold mb-2 dark:text-gray-100">Time to Review</h4>
                <ul className="list-disc list-inside text-sm">
                  {Object.entries(categoryPerformance).slice(0, 3).map(([key, category]) => (
                    <li key={key} className="text-yellow-500 dark:text-yellow-400">{category.averageTime}</li>
                  ))}
                </ul>
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center justify-center py-6 text-center">
              <p className="text-gray-600 dark:text-gray-300 mb-2">No performance data available yet</p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Complete more exercises to see personalized recommendations
              </p>
            </div>
          )}
        </div>
      </DashboardCard>
    </div>
  );
};

export default AdaptiveInsightsCard; 