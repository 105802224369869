import { db } from '../../firebase';
import { 
  doc, 
  getDoc, 
  updateDoc,
  serverTimestamp,
  writeBatch,
  deleteField
} from 'firebase/firestore';
import { 
  TestResult, 
  WrongAnswer
} from '../quiz/types';
import { StorageAdaptiveState, toRuntimeState, AdaptiveState, AdaptiveMetrics } from '../../models/AdaptiveState';
import { logger } from '../logger';
import { User } from '../../models/UserTypes';
import { AdaptiveLearningEngine } from '../adaptiveLearning';
// import { calculateAverageResponseTime } from '../../components/dashboard/utils';

// Add helper for consistent date handling
// const serializeDate = (date: Date): string => date.toISOString();
// const deserializeDate = (dateStr: string): Date => new Date(dateStr);

const aggregateAdaptiveMetrics = (
  currentMetrics: AdaptiveMetrics | undefined,
  newTestResult: TestResult,
  allTestResults: Record<string, TestResult>
): AdaptiveMetrics => {
  // Initialize engine with all test results
  const engine = new AdaptiveLearningEngine(
    null, // userProfile can be null for metrics calculation
    allTestResults,
    Object.values(allTestResults).flatMap(r => r.wrongAnswers)
  );
  // const metrics = engine.generateAdaptiveMetrics()
  // Use existing engine functions to calculate metrics
  const mastery = engine.calculateMasteryLevels();
  const recentPerformance = engine.getRecentPerformance();
  const preferredTimes = engine.analyzePreferredTimes();
  const difficultyProgression = engine.getDifficultyProgression();
  const lastSessionDate = engine.getLastSessionDate();

  // Calculate category performance using engine's utilities
  const categoryPerformance = Object.entries(engine.getCategoryStrengths()).reduce(
    (acc, [category, performance]) => ({
      ...acc,
      [category]: {
        correct: performance.correctCount,
        total: performance.totalCount
      }
    }), 
    {}
  );

  return {
    startingDifficulty: currentMetrics?.startingDifficulty ?? 5,
    endingDifficulty: engine.getOptimalDifficulty(),
    averageResponseTime: engine.calculateAverageResponseTime(newTestResult),
    categoryPerformance,
    overallSuccessRate: engine.calculateCurrentAverage(),
    totalQuestionsAttempted: Object.values(allTestResults)
      .reduce((sum, test) => sum + test.totalQuestions, 0),
    currentStreak: engine.getConsecutiveCorrect(),
    longestStreak: Math.max(
      currentMetrics?.longestStreak ?? 0,
      engine.getConsecutiveCorrect()
    ),
    lastSessionDate,
    weeklyActivityStreak: calculateWeeklyStreak(allTestResults),
    studyTimeThisWeek: calculateStudyTimeThisWeek(allTestResults),
    estimatedExamReadiness: engine.calculateExamReadiness(),
    completedCategories: Array.from(new Set(
      Object.values(allTestResults)
        .flatMap(test => test.adaptiveMetrics.completedCategories)
    )),
    mastery,
    recentPerformance,
    preferredTimes,
    difficultyProgression
  };
};

// Helper function for weekly streak calculation
const calculateWeeklyStreak = (testResults: Record<string, TestResult>): number => {
  const sortedTests = Object.values(testResults)
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  
  let streak = 0;
  let currentWeek = new Date().getTime();
  
  for (const test of sortedTests) {
    const testWeek = new Date(test.date).getTime();
    const weekDiff = Math.floor((currentWeek - testWeek) / (7 * 24 * 60 * 60 * 1000));
    
    if (weekDiff === streak) {
      streak++;
      currentWeek = testWeek;
    } else {
      break;
    }
  }
  
  return streak;
};

// Helper function for weekly study time calculation
const calculateStudyTimeThisWeek = (testResults: Record<string, TestResult>): number => {
  const weekStart = new Date();
  weekStart.setHours(0, 0, 0, 0);
  weekStart.setDate(weekStart.getDate() - weekStart.getDay());

  return Object.values(testResults)
    .filter(test => new Date(test.date) >= weekStart)
    .reduce((sum, test) => sum + (test.timeTaken || 0), 0);
};

export const saveTestResult = async (
  userId: string,
  testResult: TestResult,
  metadata: StorageAdaptiveState
): Promise<void> => {
  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    const userData = userDoc.data() as User;
    
    // Aggregate new adaptive metrics
    // const updatedAdaptiveMetrics = aggregateAdaptiveMetrics(
    //   userData.adaptiveLearning,
    //   testResult,
    //   userData.testResults
    // );
      // Initialize engine with all test results
    const engine = new AdaptiveLearningEngine(
      userData.profile,
      userData.testResults,
      Object.values(userData.testResults).flatMap(r => r.wrongAnswers)
    );
    const metrics = engine.generateAdaptiveMetrics()

    const updates: any = {
      // Existing updates
      [`testResults.${testResult.id}`]: {
        ...testResult,
        timestamp: serverTimestamp(),
        adaptiveMetrics: testResult.adaptiveMetrics,
        adaptiveState: metadata,
        completed: true
      },
      [`quizzes.${testResult.id}`]: deleteField(),
      [`quizProgress.${testResult.id}`]: deleteField(),
      
      // Add overall adaptive metrics update
      adaptiveLearning: metrics
    };

    const batch = writeBatch(db);
    batch.update(userDocRef, updates);
    await batch.commit();
    
    logger.success('TestResults', 'Test result and adaptive metrics saved', {
      testId: testResult.id,
      userId
    });
  } catch (error) {
    logger.error('TestResults', 'Error saving test result', { error });
    throw error;
  }
};

// Type guard to ensure all required fields are present
const isValidAdaptiveMetrics = (metrics: any): metrics is AdaptiveMetrics => {
  const requiredFields = [
    'startingDifficulty',
    'endingDifficulty',
    'averageResponseTime',
    'categoryPerformance',
    'overallSuccessRate',
    'totalQuestionsAttempted',
    'currentStreak',
    'longestStreak',
    'lastSessionDate',
    'weeklyActivityStreak',
    'studyTimeThisWeek',
    'estimatedExamReadiness',
    'completedCategories',
    'mastery',
    'recentPerformance',
    'preferredTimes',
    'difficultyProgression'
  ];

  return requiredFields.every(field => field in metrics);
};

export const getTestResults = async (userId: string): Promise<TestResult[]> => {
  try {
    logger.info('TestResults', `Loading results for user: ${userId}`);
    
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    const userData = userDoc.data();

    if (!userData?.testResults) {
      logger.warn('TestResults', 'No test results found for user', { userId });
      return [];
    }

    // Convert object to array
    const results = Object.entries(userData.testResults).map(([quizId, result]) => {
      const rawResult = result as any;
      
      // Construct AdaptiveMetrics from raw data or use defaults
      const adaptiveMetrics: AdaptiveMetrics = {
        startingDifficulty: rawResult.adaptiveMetrics?.startingDifficulty ?? 5,
        endingDifficulty: rawResult.adaptiveMetrics?.endingDifficulty ?? 5,
        averageResponseTime: rawResult.timeTaken 
          ? rawResult.timeTaken / (rawResult.questions?.length || 1) 
          : 0,
        categoryPerformance: rawResult.adaptiveMetrics?.categoryPerformance || {},
        overallSuccessRate: rawResult.adaptiveMetrics?.overallSuccessRate ?? 0,
        totalQuestionsAttempted: rawResult.adaptiveMetrics?.totalQuestionsAttempted ?? 0,
        currentStreak: rawResult.adaptiveMetrics?.currentStreak ?? 0,
        longestStreak: rawResult.adaptiveMetrics?.longestStreak ?? 0,
        lastSessionDate: rawResult.adaptiveMetrics?.lastSessionDate ?? new Date().toISOString(),
        weeklyActivityStreak: rawResult.adaptiveMetrics?.weeklyActivityStreak ?? 0,
        studyTimeThisWeek: rawResult.adaptiveMetrics?.studyTimeThisWeek ?? 0,
        estimatedExamReadiness: rawResult.adaptiveMetrics?.estimatedExamReadiness ?? 0,
        completedCategories: rawResult.adaptiveMetrics?.completedCategories ?? [],
        mastery: rawResult.adaptiveMetrics?.mastery ?? {
          beginner: [],
          intermediate: [],
          advanced: []
        },
        recentPerformance: rawResult.adaptiveMetrics?.recentPerformance ?? [],
        preferredTimes: rawResult.adaptiveMetrics?.preferredTimes ?? [],
        difficultyProgression: rawResult.adaptiveMetrics?.difficultyProgression ?? []
      };
      
      // Construct the full TestResult object
      const testResult: TestResult = {
        id: parseInt(quizId),
        date: rawResult.date || new Date().toISOString(),
        score: rawResult.score || 0,
        totalQuestions: rawResult.questions?.length || 0,
        percentage: rawResult.score 
          ? (rawResult.score / (rawResult.questions?.length || 1)) * 100 
          : 0,
        questions: rawResult.questions || [],
        wrongAnswers: (rawResult.wrongAnswers || []).map((wa: any) => ({
          question: wa.question,
          selectedAnswer: wa.selectedAnswer,
          correctAnswer: wa.correctAnswer,
          explanation: wa.explanation || '',
          isReviewed: wa.isReviewed || false,
          category: wa.question?.category || '',
          difficulty: wa.question?.difficulty || 5,
          timestamp: wa.timestamp || new Date().toISOString()
        })),
        type: rawResult.type || 'sample',
        category: rawResult.category || '',
        timeTaken: rawResult.timeTaken || 0,
        adaptiveMetrics
      };

      logger.debug('TestResults', 'Processed result', {
        quizId,
        hasAdaptiveMetrics: !!testResult.adaptiveMetrics,
        questionsCount: testResult.questions.length,
        wrongAnswersCount: testResult.wrongAnswers.length
      });
      
      return testResult;
    });

    // Sort results by date (newest first)
    return results.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  } catch (error) {
    console.error('Error getting test results:', error);
    throw error;
  }
};

export const loadTestResults = async (
  userId: string
): Promise<TestResult[]> => {
  if (!userId) throw new Error('No user ID provided');

  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    
    if (!userDoc.exists()) {
      logger.warn('TestResults', 'No user document found', { userId });
      return [];
    }
    
    const data = userDoc.data();
    return data?.testResults || [];
  } catch (error) {
    logger.error('TestResults', 'Error loading test results', { error });
    throw error;
  }
};

export const loadWrongAnswers = async (userId: string): Promise<WrongAnswer[]> => {
  if (!userId) throw new Error('No user ID provided');

  try {
    logger.info('WrongAnswers', `Loading wrong answers for user: ${userId}`);
    
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    
    if (!userDoc.exists()) {
      logger.warn('WrongAnswers', 'No user document found', { userId });
      return [];
    }
    
    const data = userDoc.data();
    if (!data?.testResults) {
      logger.warn('WrongAnswers', 'No test results found', { userId });
      return [];
    }

    // Convert testResults object to array and collect wrong answers
    const allWrongAnswers = Object.values(data.testResults).reduce((acc: WrongAnswer[], test: any) => {
      // Ensure wrongAnswers exists and is an array
      const validWrongAnswers = test.wrongAnswers?.filter((wa: any) => 
        wa && wa.question && typeof wa.question.id !== 'undefined'
      ) || [];
      
      logger.debug('WrongAnswers', `Processing test results`, {
        testId: test.id,
        wrongAnswersCount: validWrongAnswers.length
      });
      
      return [...acc, ...validWrongAnswers];
    }, []);

    // Remove duplicates and filter out reviewed items
    const uniqueWrongAnswers = allWrongAnswers.reduce((acc: WrongAnswer[], current: WrongAnswer) => {
      if (!current.isReviewed && 
          current.question && 
          current.question.id && 
          !acc.some(wa => wa.question?.id === current.question?.id)) {
        acc.push(current);
      }
      return acc;
    }, []);

    logger.info('WrongAnswers', 'Successfully loaded wrong answers', {
      totalWrongAnswers: allWrongAnswers.length,
      uniqueWrongAnswers: uniqueWrongAnswers.length
    });

    return uniqueWrongAnswers;
  } catch (error) {
    logger.error('WrongAnswers', 'Failed to load wrong answers', { error });
    throw new Error('Failed to load wrong answers');
  }
};

export const updateWrongAnswerExplanation = async (
  userId: string,
  questionId: number, 
  explanation: string
): Promise<void> => {
  if (!userId) throw new Error('No user ID provided');

  try {
    logger.info('WrongAnswers', 'Updating explanation', { userId, questionId });
    
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data();
    
    if (!data?.testResults) {
      logger.warn('WrongAnswers', 'No test results found to update', { userId });
      return;
    }

    // Update explanation in each test result where the question appears
    const updatedTestResults = Object.entries(data.testResults).reduce((acc, [testId, test]: [string, any]) => {
      const updatedTest = {
        ...test,
        wrongAnswers: test.wrongAnswers?.map((answer: any) => 
          answer.question.id === questionId 
            ? { ...answer, explanation } 
            : answer
        )
      };
      acc[testId] = updatedTest;
      return acc;
    }, {} as Record<string, any>);

    await updateDoc(userDocRef, { testResults: updatedTestResults });
    
    logger.success('WrongAnswers', 'Successfully updated explanation', { 
      userId, 
      questionId 
    });
  } catch (error) {
    logger.error('WrongAnswers', 'Failed to update explanation', { error });
    throw new Error('Failed to update explanation');
  }
};

export const removeWrongAnswer = async (
  userId: string,
  questionId: number
): Promise<void> => {
  if (!userId) throw new Error('No user ID provided');

  try {
    logger.info('WrongAnswers', 'Marking wrong answer as reviewed', { 
      userId, 
      questionId 
    });
    
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data();
    
    if (!data?.testResults) {
      logger.warn('WrongAnswers', 'No test results found to update', { userId });
      return;
    }

    // Mark as reviewed in each test result where the question appears
    const updatedTestResults = Object.entries(data.testResults).reduce((acc, [testId, test]: [string, any]) => {
      const updatedTest = {
        ...test,
        wrongAnswers: test.wrongAnswers?.map((answer: any) => 
          answer.question.id === questionId 
            ? { ...answer, isReviewed: true } 
            : answer
        )
      };
      acc[testId] = updatedTest;
      return acc;
    }, {} as Record<string, any>);

    await updateDoc(userDocRef, { testResults: updatedTestResults });
    
    logger.success('WrongAnswers', 'Successfully marked as reviewed', { 
      userId, 
      questionId 
    });
  } catch (error) {
    logger.error('WrongAnswers', 'Failed to mark as reviewed', { error });
    throw new Error('Failed to remove wrong answer');
  }
};

// Helper function to get test analytics
export const getTestAnalytics = async (
  userId: string
): Promise<{
  totalTests: number;
  averageScore: number;
  improvementRate: number;
  commonMistakes: { category: string; count: number }[];
}> => {
  try {
    logger.info('TestAnalytics', 'Generating analytics', { userId });

    const results = await loadTestResults(userId);

    if (results.length === 0) {
      logger.warn('TestAnalytics', 'No test results found', { userId });
      return {
        totalTests: 0,
        averageScore: 0,
        improvementRate: 0,
        commonMistakes: []
      };
    }
    const totalScore = results.reduce((sum, test) => sum + test.score, 0);
    const averageScore = totalScore / results.length;

    // Calculate improvement rate
    const sortedResults = [...results].sort((a, b) => 
      new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    const firstFive = sortedResults.slice(0, 5);
    const lastFive = sortedResults.slice(-5);
    const firstFiveAvg = firstFive.reduce((sum, test) => sum + test.score, 0) / firstFive.length;
    const lastFiveAvg = lastFive.reduce((sum, test) => sum + test.score, 0) / lastFive.length;
    const improvementRate = firstFiveAvg === 0 
      ? lastFiveAvg > 0 ? 100 : 0  // If starting from 0, any improvement is 100%, otherwise 0%
      : ((lastFiveAvg - firstFiveAvg) / firstFiveAvg) * 100;

    // Analyze common mistakes
    const mistakes = results.flatMap(test => test.wrongAnswers)
      .reduce((acc, wrong) => {
        const category = wrong.question.category;
        acc[category] = (acc[category] || 0) + 1;
        return acc;
      }, {} as Record<string, number>);

    const commonMistakes = Object.entries(mistakes)
      .map(([category, count]) => ({ category, count }))
      .sort((a, b) => b.count - a.count);

    return {
      totalTests: results.length,
      averageScore,
      improvementRate,
      commonMistakes
    };
  } catch (error) {
    logger.error('TestAnalytics', 'Failed to generate analytics', { error });
    throw error;
  }
};

// Add new function to get adaptive learning insights
// export const getAdaptiveLearningInsights = async (userId: string): Promise<Pick<AdaptiveMetrics, 'adaptiveInsights' | 'categoryStrengths'>> => {
//   // Add null checks
//   if (!userId) throw new Error('User ID is required');
  
//   const results = await loadTestResults(userId);
//   const userDoc = await getDoc(doc(db, 'users', userId));
//   const userProfile = userDoc.data()?.profile;
  
//   if (!userProfile || !results.length) {
//     return {
//       insights: {
//         weakCategories: [],
//         conceptsToReview: [],
//         recommendedDifficulty: 5,
//         suggestedPracticeFrequency: 'daily',
//         performanceTrend: 'steady',
//         timeManagement: {
//           averageQuestionTime: 0,
//           recommendedTimePerQuestion: 90,
//           timeManagementAdvice: 'Start with a steady pace of 90 seconds per question'
//         },
//         lastUpdate: new Date().toISOString()
//       },
//       categoryStrengths: {}
//     };
//   }

//   const engine = new AdaptiveLearningEngine(
//     userProfile,
//     results,
//     results.flatMap(r => r.wrongAnswers)
//   );

//   const engineInsights = engine.generateInsights();
  
//   const categoryStrengths = engine.getEnhancedCategoryStrengths();
//   const timeManagement = engine.generateTimeManagementInsights(results[results.length - 1]);

//   return {
//     insights: {
//       weakCategories: engineInsights.weakCategories,
//       conceptsToReview: engine.getConceptsToReview(),
//       recommendedDifficulty: engineInsights.recommendedDifficulty,
//       suggestedPracticeFrequency: engine.getSuggestedPracticeFrequency(),
//       performanceTrend: engineInsights.performanceTrend,
//       timeManagement: timeManagement,
//       lastUpdate: new Date().toISOString()
//     },
//     categoryStrengths
//   };
// };

// export const getAdaptiveState = async (userId: string): Promise<AdaptiveState | null> => {
//   try {
//     const userDocRef = doc(db, 'users', userId);
//     const userDoc = await getDoc(userDocRef);
//     const userData = userDoc.data();

//     const adaptiveMetrics = userData?.currentAdaptiveState;
//     if (!adaptiveMetrics) return null;

//     return toRuntimeState(adaptiveMetrics as StorageAdaptiveState, null);
//   } catch (error) {
//     console.error('Error getting adaptive state:', error);
//     throw error;
//   }
// }; 