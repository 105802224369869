import { useEffect, useRef, useState, useCallback } from 'react';
import { TimeUtils } from '../utils/adaptiveLearning';
import { logger } from '../utils/logger';

export const useQuizTimer = (difficulty = 5) => {
  const [timeSpent, setTimeSpent] = useState(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const startTimeRef = useRef<number>(0);
  const expectedTimeRef = useRef(TimeUtils.getExpectedTime(difficulty));
  const isRunningRef = useRef(false);

  const startTimer = useCallback(() => {
    if (isRunningRef.current) {
      logger.debug('QuizTimer', 'Timer already running, skipping start');
      return;
    }
    
    startTimeRef.current = Date.now();
    isRunningRef.current = true;
    
    logger.debug('QuizTimer', 'Starting timer', {
      startTime: new Date(startTimeRef.current).toISOString(),
      isRunning: isRunningRef.current
    });
    
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    
    timerRef.current = setInterval(() => {
      const elapsed = Math.floor((Date.now() - startTimeRef.current) / 1000);
      // logger.debug('QuizTimer', 'Timer tick', { elapsed });
      setTimeSpent(elapsed);
    }, 1000);
  }, []);

  const stopTimer = useCallback(() => {
    const finalTime = startTimeRef.current 
      ? Math.max(1, Math.floor((Date.now() - startTimeRef.current) / 1000))
      : 1;
    
    logger.debug('QuizTimer', 'Stopping timer', {
      startTime: new Date(startTimeRef.current).toISOString(),
      endTime: new Date().toISOString(),
      finalTime,
      stateTimeSpent: timeSpent,
      rawDifference: Date.now() - startTimeRef.current
    });
    
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    
    isRunningRef.current = false;
    return finalTime;
  }, [timeSpent]);

  const resetTimer = useCallback(() => {
    logger.debug('QuizTimer', 'Resetting timer');
    
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    isRunningRef.current = false;
    setTimeSpent(0);
    startTimeRef.current = 0;
  }, []);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        logger.debug('QuizTimer', 'Cleanup: clearing timer');
        clearInterval(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    expectedTimeRef.current = TimeUtils.getExpectedTime(difficulty);
    logger.debug('QuizTimer', 'Updated expected time', {
      difficulty,
      expectedTime: expectedTimeRef.current
    });
  }, [difficulty]);

  return {
    timeSpent,
    isRunning: isRunningRef.current,
    startTimer,
    stopTimer,
    resetTimer,
    expectedTime: expectedTimeRef.current
  };
};

export default useQuizTimer;