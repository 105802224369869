import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const PurchaseSuccessPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const [needsOnboarding, setNeedsOnboarding] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const db = getFirestore();
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const isSubscriptionActive = userData.subscriptionStatus === 'active';
          const hasCompletedOnboarding = (userData.profile != null) === true;

          setHasActiveSubscription(isSubscriptionActive);
          setNeedsOnboarding(!hasCompletedOnboarding);

          console.log('Subscription active:', isSubscriptionActive);
          console.log('Needs onboarding:', !hasCompletedOnboarding);

          if (!hasCompletedOnboarding) {
            navigate('/onboarding');
          } else if (isSubscriptionActive) {
            navigate('/quizapp');
          }
        }
      } else {
        // User is not logged in, redirect to login page
        navigate('/signin');
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  if (isLoading) {
    return <div className="new-home-container">Loading...</div>;
  }

  return (
    <div className="new-home-container">
      <div className="new-home-content text-center">
        <h1 className="text-3xl sm:text-4xl font-bold mb-4 text-white">
          Thank You for Your Purchase!
        </h1>
        <p className="text-xl mb-8 text-gray-300">
          Your subscription has been successfully activated.
        </p>
        {needsOnboarding ? (
          <Link
            to="/onboarding"
            className="inline-block px-6 py-3 text-base font-medium text-center text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Complete Onboarding
          </Link>
        ) : (
          <Link
            to="/quizapp"
            className="inline-block px-6 py-3 text-base font-medium text-center text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Start Your Exam Now
          </Link>
        )}
      </div>
    </div>
  );
};

export default PurchaseSuccessPage;