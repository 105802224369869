import React from 'react';
import { Question } from '../../utils/quiz/types';

interface QuestionDisplayProps {
  question: Question;
  showHint: boolean;
  setShowHint: (show: boolean) => void;
}

export const QuestionDisplay: React.FC<QuestionDisplayProps> = ({
  question,
  showHint,
  setShowHint
}) => (
  <div className="space-y-4">
    <p className="question-text text-lg text-gray-800 dark:text-white">
      {question.question_text}
    </p>
    
    {question.hint && question.hint.trim() !== "" && (
      <div className="mt-4">
        <button 
          className="hint-button bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200" 
          onClick={() => setShowHint(!showHint)}
        >
          {showHint ? 'Hide Hint' : 'Show Hint'}
        </button>
        {showHint && (
          <p className="hint-text mt-2 text-yellow-600 dark:text-yellow-300">
            {question.hint}
          </p>
        )}
      </div>
    )}
  </div>
); 