import React from 'react';
import { QuizError, QuizErrorCodes } from '../utils/quiz/errors';

interface Props {
  children: React.ReactNode;
  onError?: (error: Error) => void;
  onRetry?: () => void;
}

interface State {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

export class QuizErrorBoundary extends React.Component<Props, State> {
  state: State = {
    error: null,
    errorInfo: null
  };
  
  static getDerivedStateFromError(error: Error) {
    return { error };
  }
  
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ errorInfo });
    
    // Handle specific quiz errors
    if (error instanceof QuizError) {
      switch (error.code) {
        case QuizErrorCodes.SAVE_FAILED:
          // Could trigger auto-save retry logic
          this.props.onError?.(error);
          break;
        case QuizErrorCodes.STATE_INCONSISTENT:
          // Could attempt state recovery
          console.error('Quiz state inconsistent:', error);
          break;
        case QuizErrorCodes.NETWORK_ERROR:
          // Could implement offline mode or retry mechanism
          console.error('Network error in quiz:', error);
          break;
        default:
          console.error('Unhandled quiz error:', error);
      }
    }
  }
  
  handleRetry = () => {
    this.setState({ error: null, errorInfo: null });
    this.props.onRetry?.();
  };
  
  render() {
    if (this.state.error) {
      const isQuizError = this.state.error instanceof QuizError;
      const isRecoverable = isQuizError && (this.state.error as QuizError).recoverable;
      
      return (
        <div className="flex flex-col items-center justify-center p-6 space-y-4">
          <h2 className="text-xl text-red-600">
            {isQuizError ? this.state.error.message : 'Something went wrong with the quiz.'}
          </h2>
          
          {isRecoverable ? (
            <button 
              onClick={this.handleRetry}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
            >
              Retry
            </button>
          ) : (
            <button 
              onClick={() => window.location.reload()}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
            >
              Restart Quiz
            </button>
          )}
          
          {process.env.NODE_ENV === 'development' && (
            <pre className="text-sm text-gray-600 mt-4">
              {this.state.errorInfo?.componentStack}
            </pre>
          )}
        </div>
      );
    }
    
    return this.props.children;
  }
}

export default QuizErrorBoundary; 