import { useState, useEffect, useMemo, useRef, useCallback, useLayoutEffect, useContext } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { QuizProgress, QuizType } from '../utils/quiz/types';
import { initializeNewQuiz } from '../utils/quiz/core';
import { fetchQuestions, getActiveQuiz, saveQuizProgress } from '../utils/firebase/quiz';
import { logAnalyticsEvent } from '../utils/Analytics';
import { AdaptiveLearningEngine } from '../utils/adaptiveLearning';
import { logger } from '../utils/logger';
import { useAdaptiveEngine } from '../contexts/AdaptiveEngineContext';

interface UseQuizReturn {
  quizProgress: QuizProgress | null;
  isLoading: boolean;
  error: string | null;
  adaptiveEngine: AdaptiveLearningEngine | null;
  initializeQuizWithParams: (params: QuizInitParams) => Promise<QuizProgress>;
  loadExistingQuiz: () => Promise<void>;
}

interface QuizInitParams {
  type: QuizType;
  category?: string;
  adaptiveEnabled?: boolean;
}

export const useQuiz = (paramQuizId?: number): UseQuizReturn => {
  const { currentUser, userData, isLoading: authLoading } = useAuth();
  const { engine, engineId } = useAdaptiveEngine();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [quizProgress, setQuizProgress] = useState<QuizProgress | null>(null);
  const renderCount = useRef(0);
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      renderCount.current++;
      logger.debug('useQuiz', 'Initial render', { 
        count: renderCount.current,
        engineId,
        hasEngine: !!engine,
        authState: {
          hasUser: !!currentUser,
          hasUserData: !!userData,
          isAuthLoading: authLoading
        }
      });
      initialized.current = true;
    }
  }, [engineId, currentUser, userData, authLoading]);

  // Initialize quiz params
  const initializeQuizWithParams = useCallback(async (params: QuizInitParams) => {
    logger.info('useQuiz', 'Initializing quiz with params', { params });
    
    if (!currentUser || !userData || !engine) {
      logger.error('useQuiz', 'Missing required dependencies', {
        hasUser: !!currentUser,
        hasUserData: !!userData,
        hasEngine: !!engine
      });
      throw new Error('Authentication and engine required');
    }

    try {
      // 1. Fetch questions first
      const questions = await fetchQuestions({
        isSubscribed: userData.isSubscribed ?? false,
        type: params.type,
        category: params.category,
        questionIds: []
      });

      logger.debug('useQuiz', 'Fetched questions', { 
        count: questions.length,
        type: params.type 
      });

      // 2. Initialize quiz with engine
      const progress = initializeNewQuiz(
        questions, 
        params.type, 
        params.category,
        engine
      );

      // 3. Create serializable version for Firebase
      const serializableProgress: QuizProgress = {
        ...progress,
        adaptiveState: {
          ...progress.adaptiveState,
          engine: progress.adaptiveState.engine,
          currentDifficulty: progress.adaptiveState.currentDifficulty || 5,
          categoryStrengths: progress.adaptiveState.categoryStrengths || {}
        },
        date: new Date().toISOString(),
        lastUpdated: new Date().toISOString()
      };

      // 4. Save to Firebase first
      await saveQuizProgress(serializableProgress);
      
      logger.debug('useQuiz', 'Saved quiz progress', { 
        quizId: serializableProgress.quizId 
      });

      // 5. Set local state only after successful save
      setQuizProgress(serializableProgress);

      // 6. Verify quiz was saved
      const savedQuiz = await getActiveQuiz(serializableProgress.quizId);
      if (!savedQuiz) {
        throw new Error('Failed to verify quiz creation');
      }

      return serializableProgress;
    } catch (error) {
      logger.error('useQuiz', 'Failed to initialize quiz', { 
        error,
        params 
      });
      throw error;
    }
  }, [currentUser, userData, engine]);

  const loadExistingQuiz = useCallback(async () => {
    if (!paramQuizId || !currentUser || !userData || !engine) {
      logger.warn('useQuiz', 'Cannot load quiz - missing requirements', {
        hasQuizId: !!paramQuizId,
        hasUser: !!currentUser,
        hasUserData: !!userData,
        hasEngine: !!engine
      });
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      logger.debug('useQuiz', 'Fetching quiz', { 
        quizId: paramQuizId,
        userId: currentUser.uid 
      });

      const progress = await getActiveQuiz(Number(paramQuizId));
      
      if (progress) {
        logger.success('useQuiz', 'Quiz loaded successfully', {
          quizId: paramQuizId,
          questionCount: progress.questions.length,
          hasAdaptiveState: !!progress.adaptiveState
        });
        
        setQuizProgress({
          ...progress,
          adaptiveState: {
            ...progress.adaptiveState,
            engine: engine
          }
        });
      }
    } catch (error) {
      logger.error('useQuiz', 'Failed to load quiz', { error });
      const errorMessage = error instanceof Error ? error.message : 'Failed to initialize quiz';
      setError(errorMessage);
      logAnalyticsEvent('error_occurred', {
        error_type: 'initialization_error',
        error_message: errorMessage,
        component: 'useQuiz'
      });
    } finally {
      setIsLoading(false);
    }
  }, [currentUser, paramQuizId, userData, engine]);

  // Return loading state while auth is loading
  if (authLoading) {
    return {
      quizProgress: null,
      isLoading: true,
      error: null,
      adaptiveEngine: null,
      initializeQuizWithParams,
      loadExistingQuiz
    };
  }

  return {
    quizProgress,
    isLoading,
    error,
    adaptiveEngine: engine,
    initializeQuizWithParams,
    loadExistingQuiz
  };
}; 