import React from 'react';
import { Question } from '../../utils/quiz/types';

interface ResultDisplayProps {
  question: Question;
  selectedAnswer: string;
  currentExplanation: string;
  handleExplanationChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSaveAndContinue: () => void;
  showContinueButton: boolean;
  continueButtonText: string;
}

export const ResultDisplay: React.FC<ResultDisplayProps> = ({
  question,
  selectedAnswer,
  currentExplanation,
  handleExplanationChange,
  handleSaveAndContinue,
  showContinueButton,
  continueButtonText
}) => {
  
  const isCorrect = selectedAnswer === question.correct_answer;
  
  const handleContinue = () => {
    handleSaveAndContinue();
  };

  return (
    <div className="result-container mt-6 p-4 bg-white dark:bg-gray-700 rounded-lg shadow">
      {!isCorrect ? (
        <>
          <div className="explanation-section space-y-4">
            <p className="text-red-500 font-semibold">
              Incorrect. The correct answer is: {question.correct_answer}
            </p>
            <p className="text-gray-700 dark:text-white">
              <strong>Explanation:</strong> {question.explanation}
            </p>
            <p className="font-bold text-blue-600 dark:text-blue-400">
              Create a note to help remember this concept:
            </p>
            <textarea
              value={currentExplanation}
              onChange={handleExplanationChange}
              placeholder="Write your own notes about this question and answer..."
              rows={4}
              className="w-full p-2 bg-gray-100 dark:bg-gray-600 text-gray-800 dark:text-white rounded border border-gray-300 dark:border-gray-500"
            />
          </div>
          {showContinueButton && (
            <div className="button-container mt-4">
              <button 
                className="save-button bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600 transition-colors duration-200" 
                onClick={handleContinue}
                disabled={false}
              >
                {continueButtonText}
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          <p className="text-green-500 font-semibold mb-4">Correct!</p>
          {showContinueButton && (
            <div className="button-container">
              <button 
                className={`${isCorrect ? 'next-button' : 'save-button'}`}
                onClick={handleContinue}
                disabled={false}
              >
                {continueButtonText}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}; 