import { User, EducationLevel, PreferredStudyTime, LearningStyle, ChallengingTopic, WrongAnswer, AdaptiveLearningProfile, TestResult, QuizType } from '../models/UserTypes';
import questions from './questions.json';

// Convert JSON questions to match our Question type format
const formattedQuestions = questions.map(q => ({
  id: q.id,
  category: q.Category,
  question_text: q.Questions,
  option_a: q.A,
  option_b: q.B,
  option_c: q.C,
  option_d: q.D,
  correct_answer: q["CORRECT ANSWER"],
  explanation: q.Explanation,
  hint: q.HINT
}));

// Generate more realistic mock wrong answers
const generateMockWrongAnswers = (count: number): WrongAnswer[] => {
  const categories = ['ETHICS', 'DIAGNOSIS', 'TREATMENT_PLANNING'];
  const questions = [
    "What is the appropriate course of action when a client reveals suicidal ideation?",
    "Which diagnostic criteria are essential for Major Depressive Disorder?",
    "How should a social worker handle a dual relationship situation?",
    "What is the first step in developing a treatment plan for substance abuse?",
    "How do you maintain professional boundaries with clients?"
  ];

  return Array(count).fill(null).map((_, index) => ({
    question: {
      id: index + 1,
      category: categories[index % categories.length],
      question_text: questions[index % questions.length],
      option_a: "Option A - Some detailed answer here",
      option_b: "Option B - Another possible answer",
      option_c: "Option C - Third potential answer",
      option_d: "Option D - Final answer option",
      correct_answer: "B",
      explanation: "The correct approach involves considering client safety first and following ethical guidelines...",
      hint: "Think about ethical guidelines and client wellbeing."
    },
    explanation: "I struggled with understanding the ethical implications...",
    note: "Remember to review ethical guidelines section 1.2",
    date: new Date(Date.now() - index * 24 * 60 * 60 * 1000).toISOString(),
    isReviewed: index % 3 === 0,
    attempts: Math.floor(Math.random() * 3) + 1
  }));
};

// Create mock test results with wrong answers
const createMockTestResults = (): TestResult[] => {
  const mockWrongAnswers = generateMockWrongAnswers(15); // Generate 15 total wrong answers
  
  return [
    {
      id: 1,
      date: "2024-03-01",
      score: 60,
      totalQuestions: 100,
      percentage: 60,
      timeTaken: 600,
      questions: formattedQuestions,
      wrongAnswers: mockWrongAnswers.slice(0, 7),
      type: QuizType.SAMPLE,
      adaptiveMetrics: {
        startingDifficulty: 0,
        endingDifficulty: 0,
        averageResponseTime: 0,
        categoryPerformance: {}
      }
    },
    {
      id: 2,
      date: "2024-03-15",
      score: 75,
      totalQuestions: 100,
      percentage: 75,
      timeTaken: 550,
      questions: formattedQuestions,
      wrongAnswers: mockWrongAnswers.slice(7, 11),
      type: QuizType.SAMPLE,
      adaptiveMetrics: {
        startingDifficulty: 0,
        endingDifficulty: 0,
        averageResponseTime: 0,
        categoryPerformance: {}
      }
    },
    {
      id: 3,
      date: "2024-03-30",
      score: 82,
      totalQuestions: 100,
      percentage: 82,
      timeTaken: 580,
      questions: formattedQuestions,
      wrongAnswers: mockWrongAnswers.slice(11, 15),
      type: QuizType.SAMPLE,
      adaptiveMetrics: {
        startingDifficulty: 0,
        endingDifficulty: 0,
        averageResponseTime: 0,
        categoryPerformance: {}
      }
    }
  ];
};

// Mock adaptive learning data
const mockAdaptiveLearning: AdaptiveLearningProfile = {
  currentState: {
    difficulty: 7,
    consecutiveCorrect: 4,
    consecutiveIncorrect: 0,
    recentlyAskedQuestions: [1, 2, 3, 4, 5]
  },
  
  categoryStrengths: {
    'ETHICS': {
      correctCount: 45,
      totalCount: 60,
      averageTime: 75,
      lastAttempted: '2024-03-30',
      improvement: 'improving',
      successRate: 0.75,
      difficultyLevel: 8
    },
    'DIAGNOSIS': {
      correctCount: 30,
      totalCount: 50,
      averageTime: 90,
      lastAttempted: '2024-03-29',
      improvement: 'steady',
      successRate: 0.60,
      difficultyLevel: 6
    },
    'TREATMENT_PLANNING': {
      correctCount: 20,
      totalCount: 40,
      averageTime: 85,
      lastAttempted: '2024-03-28',
      improvement: 'declining',
      successRate: 0.50,
      difficultyLevel: 5
    }
  },
  
  learningPatterns: {
    averageQuestionTime: 82,
    optimalDifficulty: 7,
    lastUpdate: '2024-03-30'
  },
  
  studyPlan: {
    weeklyHours: 15,
    recommendedPace: 25,
    categoryPlans: [
      {
        category: 'ETHICS',
        hoursPerWeek: 5,
        focusAreas: ['Confidentiality', 'Boundaries'],
        recommendedResources: ['Chapter 4', 'Practice Test 2']
      },
      {
        category: 'DIAGNOSIS',
        hoursPerWeek: 6,
        focusAreas: ['DSM-5 Criteria', 'Differential Diagnosis'],
        recommendedResources: ['DSM-5 Guide', 'Case Studies']
      }
    ],
    milestones: [
      {
        date: '2024-04-15',
        targetScore: 75,
        categoriesComplete: ['ETHICS'],
        recommendedActions: ['Complete Practice Test 3', 'Review weak areas']
      }
    ]
  },
  
  insights: {
    weakCategories: ['TREATMENT_PLANNING', 'DIAGNOSIS'],
    conceptsToReview: ['Differential Diagnosis', 'Treatment Planning Models'],
    recommendedDifficulty: 7,
    suggestedPracticeFrequency: 'daily',
    performanceTrend: 'improving',
    timeManagement: {
      averageQuestionTime: 82,
      recommendedTimePerQuestion: 75,
      timeManagementAdvice: 'Spend less time on easy questions'
    },
    lastUpdate: '2024-03-30'
  },
  
  quickMetrics: {
    overallSuccessRate: 0.68,
    totalQuestionsAttempted: 150,
    averageQuestionTime: 82,
    currentStreak: 4,
    longestStreak: 7,
    
    lastSessionDate: '2024-03-30',
    weeklyActivityStreak: 3,
    studyTimeThisWeek: 720, // minutes
    
    estimatedExamReadiness: 72,
    completedCategories: ['ETHICS'],
    mastery: {
      beginner: ['TREATMENT_PLANNING'],
      intermediate: ['DIAGNOSIS'],
      advanced: ['ETHICS']
    },
    
    recentPerformance: [
      {
        date: '2024-03-30',
        score: 82,
        category: 'ETHICS',
        questionsAttempted: 20
      },
      {
        date: '2024-03-29',
        score: 75,
        category: 'DIAGNOSIS',
        questionsAttempted: 15
      },
      {
        date: '2024-03-28',
        score: 68,
        category: 'TREATMENT_PLANNING',
        questionsAttempted: 18
      }
    ],
    
    preferredTimes: [
      {
        dayOfWeek: 'Monday',
        timeOfDay: 'evening',
        averagePerformance: 0.75
      },
      {
        dayOfWeek: 'Wednesday',
        timeOfDay: 'morning',
        averagePerformance: 0.82
      }
    ],
    
    difficultyProgression: [
      {
        date: '2024-03-28',
        difficulty: 5,
        successRate: 0.65
      },
      {
        date: '2024-03-29',
        difficulty: 6,
        successRate: 0.70
      },
      {
        date: '2024-03-30',
        difficulty: 7,
        successRate: 0.75
      }
    ]
  },
  
  currentSession: {
    startTime: '2024-03-30T15:00:00Z',
    questionsAnswered: 20,
    correctAnswers: 15,
    averageResponseTime: 78,
    categoryFocus: ['ETHICS', 'DIAGNOSIS'],
    initialDifficulty: 6,
    currentDifficulty: 7
  }
};
// Create mock user using the User class with corrected properties
export const mockUser = new User({
    id: "mock-user-123",
    email: "test@example.com",
    profile: {
        name: "Jane Doe",
        examDate: "2024-12-25",
        studyGoal: "Pass LCSW exam in first attempt",
        educationLevel: EducationLevel.MASTERS,
        lastUpdated: new Date().toISOString(),
        preferredStudyTime: PreferredStudyTime.EVENING,
        challengingTopics: [
            ChallengingTopic.DIAGNOSIS,
            ChallengingTopic.ETHICS
        ],
        learningStyle: LearningStyle.VISUAL,
        onboardingCompleted: true
    },
    quizProgress: null,
    testResults: createMockTestResults(),
    subscriptionStatus: "active",
    adaptiveLearning: mockAdaptiveLearning,
    wrongAnswers: [] // Moved wrongAnswers to User properties
});

// Export wrong answers separately if needed
export const mockWrongAnswers: WrongAnswer[] = Object.values(mockUser.testResults).reduce(
  (acc, test) => [...acc, ...test.wrongAnswers], 
  [] as WrongAnswer[]
);

// Export formatted questions for use in other components
export const availableQuestions = formattedQuestions;