import { format } from 'date-fns';


export interface QuestionTiming {
    startTime: number;
    endTime?: number;
}

export const formatExamDate = (dateString: string | undefined | null) => {
    if (!dateString) return null;
    try {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return null;
        return format(date, 'MMMM d, yyyy');
    } catch {
        return null;
    }
};

export const calculateDaysUntilExam = (dateString: string | undefined | null) => {
    if (!dateString) return null;
    try {
        const examDate = new Date(dateString);
        if (isNaN(examDate.getTime())) return null;
        return Math.ceil((examDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
    } catch {
        return null;
    }
};

export const calculateAverageResponseTime = (
    timings?: Record<number, QuestionTiming>
  ): number => {
    if (!timings) return 0;
    
    const completedTimings = Object.values(timings).filter(t => t.endTime);
    if (!completedTimings.length) return 0;
    
    const totalTime = completedTimings.reduce((sum, timing) => 
      sum + ((timing.endTime! - timing.startTime) / 1000), 0);
    
    return totalTime / completedTimings.length;
  };