import React, { 
  createContext, 
  useContext, 
  useEffect, 
  useState, 
  useCallback 
} from 'react';
import { 
  User as FirebaseUser,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword as firebaseCreateUser,
  sendEmailVerification,
  sendPasswordResetEmail,
  confirmPasswordReset,
  verifyPasswordResetCode
} from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { User as AppUser } from '../models/UserTypes';
import { createUserInFirestore } from '../utils/firebase/user';
import { MigrationService } from '../services/MigrationService';

interface AuthContextType {
  currentUser: FirebaseUser | null;
  userData: AppUser | null;
  isLoading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  logOut: () => Promise<void>;
  refreshUserData: () => Promise<void>;
  createUser: (email: string, password: string) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  confirmPasswordReset: (oobCode: string, newPassword: string) => Promise<void>;
  verifyPasswordResetCode: (oobCode: string) => Promise<string>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ 
  children 
}) => {
  const [state, setState] = useState(() => ({
    currentUser: null as FirebaseUser | null,
    userData: null as AppUser | null,
    isLoading: true
  }));

  const signIn = async (email: string, password: string) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth, 
        email, 
        password
      );
      await loadUserData(userCredential.user.uid);
    } catch (error) {
      console.error('Sign in error:', error);
      throw error;
    }
  };

  const logOut = async () => {
    try {
      await signOut(auth);
      setState(prev => ({
        ...prev,
        userData: null
      }));
    } catch (error) {
      console.error('Sign out error:', error);
      throw error;
    }
  };

  const loadUserData = useCallback(async (userId: string): Promise<() => void> => {
    setState(prev => ({ ...prev, isLoading: true }));
    
    const userDocRef = doc(db, 'users', userId);
    return onSnapshot(userDocRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setState(prev => ({
          currentUser: prev.currentUser,
          userData: new AppUser({
            id: userId,
            email: prev.currentUser?.email || '',
            profile: data.profile,
            quizProgress: data.quizProgress,
            testResults: data.testResults || {},
            wrongAnswers: data.wrongAnswers || {},
            quizzes: data.quizzes || {},
            subscriptionStatus: data.subscriptionStatus || 'inactive',
            adaptiveLearning: data.adaptiveLearning,
            stripeCustomerId: data.stripeCustomerId,
            subscriptionId: data.subscriptionId
          }),
          isLoading: false
        }));
      } else {
        setState(prev => ({
          ...prev,
          userData: null,
          isLoading: false
        }));
      }
    });
  }, []);

  const refreshUserData = async () => {
    if (state.currentUser) {
      await loadUserData(state.currentUser.uid);
    }
  };

  const createUser = async (email: string, password: string) => {
    try {
      const userCredential = await firebaseCreateUser(auth, email, password);
      await sendEmailVerification(userCredential.user);
      await createUserInFirestore(userCredential.user.uid, email);
      await loadUserData(userCredential.user.uid);
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
  };

  const resetPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error('Password reset error:', error);
      throw error;
    }
  };

  const verifyResetCode = async (oobCode: string): Promise<string> => {
    try {
      return await verifyPasswordResetCode(auth, oobCode);
    } catch (error) {
      console.error('Error verifying reset code:', error);
      throw error;
    }
  };

  const confirmPasswordResetCode = async (oobCode: string, newPassword: string): Promise<void> => {
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
    } catch (error) {
      console.error('Error confirming password reset:', error);
      throw error;
    }
  };

  useEffect(() => {
    let unsubscribeUser: () => void;
    let unsubscribeData: () => void;

    const setupAuth = async () => {
      unsubscribeUser = onAuthStateChanged(auth, async (user) => {
        setState(prev => ({
          ...prev,
          currentUser: user,
          isLoading: false
        }));

        if (user) {
          unsubscribeData = await loadUserData(user.uid);
        } else {
          setState(prev => ({
            ...prev,
            userData: null
          }));
        }
      });
    };

    setupAuth();

    return () => {
      if (unsubscribeUser) unsubscribeUser();
      if (unsubscribeData) unsubscribeData();
    };
  }, [loadUserData]);

  const value = {
    currentUser: state.currentUser,
    userData: state.userData,
    isLoading: state.isLoading,
    signIn,
    logOut,
    refreshUserData,
    createUser,
    resetPassword,
    confirmPasswordReset: confirmPasswordResetCode,
    verifyPasswordResetCode: verifyResetCode
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook for using auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// HOC to protect routes that require authentication
export const withAuth = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  const WithAuthComponent = (props: P) => {
    const { currentUser, isLoading } = useAuth();

    if (isLoading) {
      return <div>Loading...</div>; // Or your loading component
    }

    if (!currentUser) {
      // Redirect to login or show unauthorized message
      return <div>Please log in to access this page</div>;
    }

    return <WrappedComponent {...props} />;
  };

  // Set display name for the wrapped component
  WithAuthComponent.displayName = `WithAuth(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return WithAuthComponent;
}; 