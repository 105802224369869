import { Question, QuizProgress } from './types';

export const sanitizeQuestion = (question: any): Question => {
  return {
    id: question.id || Date.now(),
    category: question.category || question.Category,
    question_text: question.question_text || question.Questions,
    option_a: question.option_a || question.A,
    option_b: question.option_b || question.B,
    option_c: question.option_c || question.C,
    option_d: question.option_d || question.D,
    option_e: question.option_e || question.E || '',
    correct_answer: question.correct_answer || question["CORRECT ANSWER"],
    explanation: question.explanation || question.Explanation || '',
    hint: question.hint || question.HINT || '',
    selected_answer: question.selected_answer || null
  };
};

export const validateQuestions = (questions: Question[]): Question[] => {
  // Remove duplicates based on question ID
  const uniqueQuestions = questions.filter((q, index, self) => 
    self.findIndex(sq => sq.id === q.id) === index
  );
  return uniqueQuestions;
};

export const shuffleArray = <T,>(array: T[]): T[] => {
  try {
    return [...array].sort(() => Math.random() - 0.5);
  } catch (error) {
    console.error('Error shuffling array:', error);
    throw new Error('Failed to shuffle array');
  }
};

export const validateQuizProgress = (progress: QuizProgress): boolean => {
  if (!progress || typeof progress !== 'object') return false;
  if (!Array.isArray(progress.questions)) return false;
  if (typeof progress.currentQuestionIndex !== 'number') return false;
  if (progress.currentQuestionIndex > progress.questions.length) return false;
  return true;
};

export const validateAnswer = (answer: string, question: Question): boolean => {
  if (!answer || typeof answer !== 'string') return false;
  if (!question.option_a && !question.option_b && !question.option_c && !question.option_d) return false;
  if (!question.correct_answer) return false;
  return true;
}; 