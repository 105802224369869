export const ASWB_CATEGORIES = [
    'IA. HUMAN GROWTH AND DEVELOPMENT',
    'IB. HUMAN BEHAVIOR IN THE SOCIAL ENVIRONMENT',
    'IC. Diversity and discrimination',
    'IIA. BIOPSYCHOSOCIAL HISTORY AND COLLATERAL DATA',
    'IIB. ASSESSMENT AND DIAGNOSIS',
    'IIC. TREATMENT PLANNING',
    'IIIA. THERAPEUTIC RELATIONSHIP',
    'IIIB. THE INTERVENTION PROCESS',
    'IIIC. SERVICE DELIVERY AND MANAGEMENT OF CASES',
    'IIID. CONSULTATION AND INTERDISCIPLINARY COLLABORATION',
    'IVA. PROFESSIONAL VALUES AND ETHICAL ISSUES',
    'IVB. CONFIDENTIALITY',
    'IVC. PROFESSIONAL DEVELOPMENT AND USE OF SELF'
  ];
  
  // Add new type for quiz details
export type QuizTypeDetails = {
      title: string;
      description: string;
      duration: string;
      questionCount?: number;
      features: string[];
  };
  
export const QUIZ_DETAILS: Record<string, QuizTypeDetails> = {
      category: {
          title: 'Category Practice',
          description: 'Focus on mastering specific ASWB exam topics',
          duration: 'Self-paced practice',
          features: [
              'Questions from selected category only',
              'Detailed explanations for each answer',
              'Track progress by category',
              'Pause and resume anytime'
          ]
      },
    full: {
        title: 'Full Length Test',
        description: 'Simulate the actual ASWB exam experience',
        duration: '4 hours',
        questionCount: 170,
        features: [
              'Timed test environment',
              'Questions from all categories',
              'Mirrors actual exam format',
              'Comprehensive score report'
          ]
      },
      sample: {
          title: 'Sample Quiz',
          description: 'Rapid practice session with mixed topics',
          duration: '~30 minutes',
          questionCount: 30,
          features: [
              'Mixed category questions',
              'Perfect for daily practice',
              'Quick performance feedback',
              'Focus on high-yield topics'
          ]
    }
};