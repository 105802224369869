import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from './utils/Analytics';
import LoadingSpinner from './components/LoadingSpinner';
import ErrorBoundary from './components/ErrorBoundary';
import { FiUser, FiCreditCard, FiLock, FiMessageSquare, FiLogOut, FiShoppingCart } from 'react-icons/fi';

const UserSettings: React.FC = () => {
  const { currentUser, userData, isLoading, logOut } = useAuth();
  const navigate = useNavigate();
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [isSendingFeedback, setIsSendingFeedback] = useState(false);

  useEffect(() => {
    if (currentUser) {
      logAnalyticsEvent(ANALYTICS_EVENTS.SETTINGS_PAGE_VIEWED, {
        userId: currentUser.uid,
        isSubscribed: userData?.subscriptionStatus === 'active',
        timestamp: Date.now()
      });
    }
  }, [currentUser, userData]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  const handleUpdateProfile = () => {
    logAnalyticsEvent(ANALYTICS_EVENTS.SETTINGS_UPDATE_PROFILE, {
      userId: currentUser?.uid || '',
      timestamp: Date.now()
    });
    navigate('/onboarding', { state: { isUpdate: true } });
  };

  const handleManageSubscription = () => {
    if (!userData?.stripeCustomerId) {
      console.error('No Stripe customer ID found');
      return;
    }

    logAnalyticsEvent(ANALYTICS_EVENTS.SETTINGS_MANAGE_SUBSCRIPTION, {
      userId: currentUser?.uid || '',
      currentStatus: userData?.subscriptionStatus || 'inactive',
      timestamp: Date.now()
    });
    
    window.location.href = `https://billing.stripe.com/p/session/${userData.stripeCustomerId}`;
  };

  const handleSubscribe = () => {
    logAnalyticsEvent(ANALYTICS_EVENTS.SUBSCRIPTION_CLICKED, {
      userId: currentUser?.uid || '',
      source: 'settings_page',
      timestamp: Date.now()
    });
    navigate('/pricing');
  };

  const handleChangePassword = () => {
    logAnalyticsEvent(ANALYTICS_EVENTS.SETTINGS_CHANGE_PASSWORD, {
      userId: currentUser?.uid || '',
      timestamp: Date.now()
    });
    navigate('/reset-password');
  };

  const handleSendFeedback = async () => {
    if (!feedbackMessage.trim()) return;
    
    setIsSendingFeedback(true);
    try {
      logAnalyticsEvent(ANALYTICS_EVENTS.SETTINGS_SEND_FEEDBACK, {
        userId: currentUser?.uid || '',
        feedbackLength: feedbackMessage.length,
        timestamp: Date.now()
      });
      setFeedbackMessage('');
      // TODO: Implement feedback submission
    } catch (error) {
      console.error('Error sending feedback:', error);
    } finally {
      setIsSendingFeedback(false);
    }
  };

  const handleLogOut = async () => {
    try {
      logAnalyticsEvent(ANALYTICS_EVENTS.SETTINGS_USER_LOGOUT, {
        userId: currentUser?.uid || '',
        timestamp: Date.now()
      });
      await logOut();
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <ErrorBoundary>
    <div className="new-home-container">
    <div className="new-home-content">
    <div className="new-home-inner">
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8 text-gray-800 dark:text-gray-200">
          Account Settings
        </h1>

        <div className="space-y-6">
          {/* Profile Section */}
          <section className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-3">
                <FiUser className="w-6 h-6 text-blue-500" />
                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                  Profile Information
                </h2>
              </div>
              <button
                onClick={handleUpdateProfile}
                className="text-sm text-gray-500 hover:text-blue-500"
              >
                Edit
              </button>
            </div>
            {/* Add your profile information fields here */}
          </section>

            {/* Subscription Section */}
            <section className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow">
            <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-3">
                {userData?.subscriptionStatus === 'active' ? (
                    <FiCreditCard className="w-6 h-6 text-green-500" />
                ) : (
                    <FiShoppingCart className="w-6 h-6 text-blue-500" />
                )}
                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                    {userData?.subscriptionStatus === 'active' ? 'Subscription' : 'Subscribe'}
                </h2>
                </div>
                {userData?.subscriptionStatus === 'active' ? (
                <button
                    onClick={handleManageSubscription}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
                >
                    Manage Subscription
                </button>
                ) : (
                <button
                    onClick={handleSubscribe}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition flex items-center space-x-2"
                >
                    <FiShoppingCart className="w-4 h-4" />
                    <span>Subscribe Now</span>
                </button>
                )}
            </div>
            <div className="text-gray-600 dark:text-gray-400">
                <p>Status: {userData?.subscriptionStatus === 'active' ? (
                <span className="text-green-500 font-medium">Active</span>
                ) : (
                <span className="text-gray-500 font-medium">Inactive</span>
                )}</p>
                {userData?.subscriptionStatus === 'active' ? (
                <p className="text-sm mt-1">
                    Manage your subscription settings, payment method, and billing history
                </p>
                ) : (
                <p className="text-sm mt-1">
                    Subscribe to unlock premium features and enhance your learning experience
                </p>
                )}
            </div>
            </section>

          {/* Password Section */}
          <section className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-3">
                <FiLock className="w-6 h-6 text-purple-500" />
                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                  Password
                </h2>
              </div>
              <button
                onClick={handleChangePassword}
                className="text-sm text-gray-500 hover:text-purple-500"
              >
                Change
              </button>
            </div>
            {/* Add your password change fields here */}
          </section>
          
          {/* Feedback Section */}
          <section className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-3">
                <FiMessageSquare className="w-6 h-6 text-teal-500" />
                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                  Need Help?
                </h2>
              </div>
              <a href="mailto:support@socialworktestprepacademy.com" className="footer-link text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100">
                Contact us!
                </a>
            </div>
            {/* Add your feedback fields here */}
          </section>
          

          {/* Logout Section */}
          <section className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-3">
                <FiLogOut className="w-6 h-6 text-red-500" />
                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                  Logout
                </h2>
              </div>
              <button
                onClick={handleLogOut}
                className="text-sm text-gray-500 hover:text-red-500"
              >
                Logout
              </button>
            </div>
            {/* Add your logout fields here */}
          </section>
        </div>
      </div>
      </div>
      </div>
      </div>
    </ErrorBoundary>
  );
};

export default UserSettings; 