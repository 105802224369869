import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateUserProfile } from './utils/firebase/user';
import { UserProfile, EducationLevel, PreferredStudyTime, ChallengingTopic, LearningStyle } from './models/UserTypes';
import LoadingSpinner from './components/LoadingSpinner';
import { logAnalyticsEvent, ANALYTICS_EVENTS } from './utils/Analytics';
import { useAuth } from './contexts/AuthContext';

interface OnboardingProps {
  isModal?: boolean;
  onComplete?: () => void;
}

const Onboarding: React.FC<OnboardingProps> = ({ isModal = false, onComplete }) => {
  const { currentUser } = useAuth();
  const [profile, setProfile] = useState<UserProfile>({
    name: '',
    examDate: '',
    studyGoal: '',
    onboardingCompleted: false,
    challengingTopics: [],
    educationLevel: EducationLevel.BACHELORS,
    preferredStudyTime: PreferredStudyTime.MORNING,
    learningStyle: LearningStyle.VISUAL
  });
  const [hasExamDate, setHasExamDate] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate('/signin');
      return;
    }
    logAnalyticsEvent(ANALYTICS_EVENTS.ONBOARDING_STARTED, {});
  }, [currentUser, navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setProfile(prev => ({ ...prev, [name]: value }));
  };

  const handleTopicChange = (topic: ChallengingTopic) => {
    setProfile(prev => ({
      ...prev,
      challengingTopics: prev.challengingTopics ? 
        (prev.challengingTopics.includes(topic)
          ? prev.challengingTopics.filter(t => t !== topic)
          : [...prev.challengingTopics, topic])
        : [topic]
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser) return;

    logAnalyticsEvent(ANALYTICS_EVENTS.ONBOARDING_COMPLETED, {
      educationLevel: profile.educationLevel,
      preferredStudyTime: profile.preferredStudyTime,
      learningStyle: profile.learningStyle,
      hasExamDate,
      challengingTopicsCount: profile.challengingTopics?.length
    });

    setIsLoading(true);
    try {
      const profileToSubmit = {
        ...profile,
        examDate: hasExamDate ? profile.examDate : 'Not scheduled',
        onboardingCompleted: true
      };
      await updateUserProfile(currentUser.uid, profileToSubmit);
            
      if (isModal && onComplete) {
        onComplete();
        window.location.reload();
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error updating user profile:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="new-home-container">
        <div className="new-home-content">
          <h2 className="text-2xl font-bold mb-4 text-center text-gray-800 dark:text-gray-200">
            Setting Up Your Profile...
          </h2>
          <div className="new-home-inner">
            <LoadingSpinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={isModal ? 'h-full' : 'new-home-container'}>
      <div className={isModal ? 'h-full p-6' : 'new-home-content'}>
        <form onSubmit={handleSubmit} className={`bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md w-full ${
          isModal ? 'h-full max-h-[80vh] overflow-y-auto' : 'max-w-md'
        }`}>
          <h2 className="text-2xl font-bold mb-4 text-center text-gray-800 dark:text-gray-200">Create Your Profile</h2>
          
          <label className="block mb-2 text-gray-700 dark:text-gray-200">
            Name:
            <input
              type="text"
              name="name"
              value={profile.name}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            />
          </label>

          <div className="mb-2">
            <label className="inline-flex items-center text-gray-700 dark:text-gray-200">
              <input
                type="checkbox"
                checked={hasExamDate}
                onChange={() => setHasExamDate(!hasExamDate)}
                className="mr-2 dark:bg-gray-700 dark:border-gray-600"
              />
              I have an exam scheduled
            </label>
          </div>

          {hasExamDate && (
            <label className="block mb-2 text-gray-700 dark:text-gray-200">
              Exam Date:
              <input
                type="date"
                name="examDate"
                value={profile.examDate}
                onChange={handleChange}
                required
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
              />
            </label>
          )}

          <label className="block mb-2 text-gray-700 dark:text-gray-200">
            Study Goal:
            <input
              type="text"
              name="studyGoal"
              value={profile.studyGoal}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            />
          </label>

          <label className="block mb-2 text-gray-700 dark:text-gray-200">
            Education Level:
            <select
              name="educationLevel"
              value={profile.educationLevel}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            >
              {Object.values(EducationLevel).map(level => (
                <option key={level} value={level}>{level}</option>
              ))}
            </select>
          </label>

          <label className="block mb-2 text-gray-700 dark:text-gray-200">
            Preferred Study Time:
            <select
              name="preferredStudyTime"
              value={profile.preferredStudyTime}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            >
              {Object.values(PreferredStudyTime).map(time => (
                <option key={time} value={time}>{time}</option>
              ))}
            </select>
          </label>

          <fieldset className="block mb-2 text-gray-700 dark:text-gray-200">
            <legend className="mb-1">Challenging Topics:</legend>
            {Object.values(ChallengingTopic).map(topic => (
              <label key={topic} className="flex items-center">
                <input
                  type="checkbox"
                  checked={profile.challengingTopics?.includes(topic)}
                  onChange={() => handleTopicChange(topic)}
                  className="mr-2"
                />
                {topic}
              </label>
            ))}
          </fieldset>

          <label className="block mb-2 text-gray-700 dark:text-gray-200">
            Learning Style:
            <select
              name="learningStyle"
              value={profile.learningStyle}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            >
              {Object.values(LearningStyle).map(style => (
                <option key={style} value={style}>{style}</option>
              ))}
            </select>
          </label>

          <button type="submit" className="mt-4 w-full bg-blue-500 text-white p-2 rounded-md dark:bg-blue-700">
            Finish Your Profile
          </button>
        </form>
      </div>
    </div>
  );
};

export default Onboarding;