import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Header from './Header';
import Footer from './Footer';
import Feedback from './Feedback';
import Home from './Home';
import QuizApp from './QuizApp';
import UserSettings from './UserSettings';
import FinishSignIn from './FinishSignIn';
import SignIn from './SignIn';
import PrivacyPolicy from './PrivacyPolicy';
import Disclosures from './Disclosures';
import AboutUs from './AboutUs';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import NotFound from './NotFound';
import ForgotPassword from './ForgotPassword';
import Onboarding from './Onboarding';
import PrivateRoute from './components/PrivateRoute';
import PricingPage from './Pricing';
import PurchaseSuccessPage from './PurchaseSuccessPage';
import Dashboard from './components/Dashboard';
import { initializeErrorTracking } from './utils/ErrorTracking';
import { trackPageLoad, trackScrollDepth } from './utils/PerformanceTracking';
import TestAdaptiveQuiz from './pages/TestAdaptiveQuiz';
import { AuthProvider } from './contexts/AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import { AdaptiveEngineProvider } from './contexts/AdaptiveEngineContext';


const stripePromise = loadStripe(process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
  : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV!);

function App() {
  useEffect(() => {
    initializeErrorTracking();
    trackPageLoad();
    trackScrollDepth();
  }, []);

  return (
      <ErrorBoundary>
        <Elements stripe={stripePromise}>
          <Router>
            <AuthProvider>
              <AdaptiveEngineProvider>
                <div className="main-container">
                  <Header />
                <main className="main-content">
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/quiz/:quizId" element={
                      <PrivateRoute>
                        <QuizApp />
                      </PrivateRoute>
                      } />
                    <Route path="/finish-signin" element={<FinishSignIn />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/feedback" element={
                      <PrivateRoute>
                        <Feedback />
                      </PrivateRoute>
                      } />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/pricing" element={<PricingPage />} />
                    <Route path="/purchase-success" element={<PurchaseSuccessPage />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/disclosures" element={<Disclosures />} />
                    <Route path="/onboarding" element={
                      <PrivateRoute>
                        <Onboarding />
                      </PrivateRoute>
                    } />
                    <Route path="/settings" element={
                      <PrivateRoute>
                        <UserSettings />
                      </PrivateRoute>
                    } />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/test-adaptive" element={<TestAdaptiveQuiz />} />
                    {/* Replace the inline 404 with the new NotFound component */}
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </main>
                <Footer />
              </div>
              <SpeedInsights />
              <Analytics />
              </AdaptiveEngineProvider>
            </AuthProvider>
          </Router>
        </Elements>
      </ErrorBoundary>
  );
}

export default App;
