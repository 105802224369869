import { format } from 'date-fns';
import { TestResult } from "../../models/UserTypes";
import React, { useState } from 'react';

interface TestScoresProps {
    testResults?: TestResult;
}

const TestScores: React.FC<TestScoresProps> = ({ testResults }) => {
    return (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 border border-gray-200 dark:border-gray-600 h-full overflow-auto">
            <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">Latest Score</h2>
            {testResults ? (
                <>
                    <p className="text-3xl font-bold text-green-600 dark:text-green-400">
                        {Math.round((testResults.score / testResults.totalQuestions) * 100)}%
                    </p>
                    <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                        {testResults.score} out of {testResults.totalQuestions} questions
                    </p>
                    <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                        Date: {format(new Date(testResults.date), 'MMMM d, yyyy')}
                    </p>
                </>
            ) : (
                <p className="text-gray-600 dark:text-gray-400">No tests taken yet</p>
            )}
        </div>
    );
};

export default TestScores; 