type LogLevel = 'info' | 'warn' | 'error' | 'success' | 'debug' | 'metric' | 'time';

interface LogConfig {
  emoji: string;
  color: string;
}

const LOG_LEVELS: Record<LogLevel, LogConfig> = {
  info: { emoji: '📘', color: '#2196F3' },
  warn: { emoji: '⚠️', color: '#FFC107' },
  error: { emoji: '🚨', color: '#F44336' },
  success: { emoji: '✅', color: '#4CAF50' },
  debug: { emoji: '🔍', color: '#9C27B0' },
  metric: { emoji: '📊', color: '#FF9800' },
  time: { emoji: '⏱️', color: '#607D8B' }
};

class DevLogger {
  private static instance: DevLogger;
  private isEnabled: boolean;

  private constructor() {
    this.isEnabled = process.env.NODE_ENV === 'development';
  }

  static getInstance(): DevLogger {
    if (!DevLogger.instance) {
      DevLogger.instance = new DevLogger();
    }
    return DevLogger.instance;
  }

  private formatMessage(level: LogLevel, component: string, message: string): string {
    const timestamp = new Date().toISOString();
    return `${LOG_LEVELS[level].emoji} [${timestamp}] [${component}] ${message}`;
  }

  private log(level: LogLevel, component: string, message: string, data?: any) {
    if (!this.isEnabled) return;

    const formattedMessage = this.formatMessage(level, component, message);
    const style = `color: ${LOG_LEVELS[level].color}; font-weight: bold;`;

    console.log(`%c${formattedMessage}`, style);
    if (data) {
      console.log('  └─ ', data);
    }
  }

  info(component: string, message: string, data?: any) {
    this.log('info', component, message, data);
  }

  warn(component: string, message: string, data?: any) {
    this.log('warn', component, message, data);
  }

  error(component: string, message: string, data?: any) {
    this.log('error', component, message, data);
  }

  success(component: string, message: string, data?: any) {
    this.log('success', component, message, data);
  }

  debug(component: string, message: string, data?: any) {
    this.log('debug', component, message, data);
  }

  metric(component: string, message: string, data?: any) {
    this.log('metric', component, message, data);
  }

  time(component: string, message: string, data?: any) {
    this.log('time', component, message, data);
  }

  // Group related logs
  group(component: string, groupName: string, callback: () => void) {
    if (!this.isEnabled) return;
    
    console.group(`${LOG_LEVELS.info.emoji} [${component}] ${groupName}`);
    callback();
    console.groupEnd();
  }
}

export const logger = DevLogger.getInstance(); 

// CHEAT SHEET

/*

Different log types and when to use them:
logger.info('ComponentName', 'Starting process');  // 📘 General information
logger.warn('ComponentName', 'Potential issue');   // ⚠️ Warnings/potential problems
logger.error('ComponentName', 'Failed to load');   // 🚨 Errors/exceptions
logger.success('ComponentName', 'Task complete');  // ✅ Successful operations
logger.debug('ComponentName', 'State update');     // 🔍 Debugging information
logger.metric('ComponentName', 'Performance');     // 📊 Metrics/measurements
logger.time('ComponentName', 'Operation timing');  // ⏱️ Timing information

With data objects:
logger.info('ComponentName', 'User state', {
  isLoggedIn: true,
  lastActive: new Date()
});

Grouping related logs:
logger.group('ComponentName', 'State Update', () => {
  logger.info('ComponentName', 'Current state', currentState);
  logger.metric('ComponentName', 'Performance', metrics);
  logger.debug('ComponentName', 'Debug data', debugInfo);
});

*/