import React, { useMemo } from 'react';
import { Question } from '../models/QuizTypes';

export interface AnswerOptionsProps {
  currentQuestion: Question;
  selectedAnswer: string | null;
  showResult: boolean;
  handleAnswer: (answer: string) => void;
  isCorrect: (option: string) => boolean;
}

const AnswerOptions: React.FC<AnswerOptionsProps> = ({
  currentQuestion,
  selectedAnswer,
  showResult,
  handleAnswer,
  isCorrect
}) => {
  const options = useMemo(() => {
    return ['A', 'B', 'C', 'D', 'E'].map((option) => {
      const optionKey = `option_${option.toLowerCase()}` as keyof Question;
      const optionText = currentQuestion[optionKey];

      if (!optionText || optionText === "") return null;

      return {
        letter: option,
        text: optionText,
        key: `${currentQuestion.id}-${option}`  // Add unique key based on question ID
      };
    }).filter(Boolean);
  }, [currentQuestion.id, currentQuestion]); // Add proper dependencies

  return (
    <>
      {options.map((option) => {
        if (!option) return null;
        
        // const isSelected = selectedAnswer === option.letter;
        // const isDisabled = showResult || !!selectedAnswer;
        
        return (
          <div key={option.key} className="option-container flex items-center">
            <span className="option-letter font-bold mr-2">{option.letter}.</span>
            <button
              className={`option-button flex-grow p-3 rounded-lg border ${
                selectedAnswer === option.letter 
                  ? (isCorrect(option.letter) ? 'bg-green-100 border-green-500' : 'bg-red-100 border-red-500')
                  : 'bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600'
              } hover:bg-gray-50 dark:hover:bg-gray-600 transition-colors duration-200`}
              onClick={() => handleAnswer(option.letter)}
              disabled={showResult}
            >
              {option.text}
              {showResult && selectedAnswer === option.letter && (
                <span className="result-icon ml-2">
                  {isCorrect(option.letter) ? '✓' : '✗'}
                </span>
              )}
            </button>
          </div>
        );
      })}
    </>
  );
};

export default React.memo(AnswerOptions);