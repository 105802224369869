import React, { createContext, useContext, useReducer, useMemo, useCallback, useRef, useEffect } from 'react';
import { QuizState, QuizAction, QuizType } from '../../../models/QuizTypes';
import { quizReducer, initialState } from './QuizReducer';
import { useAdaptiveEngine } from '../../../contexts/AdaptiveEngineContext';
import { logger } from '../../../utils/logger';
import { getQuizSummary } from '../../../utils/quiz/core';
import { saveQuizProgress } from '../../../utils/firebase/quiz';
import { QuizError } from '../../../utils/quiz/errors';
import { QuizProgress } from '../../../models/UserTypes';

interface QuizContextType {
  state: QuizState;
  dispatch: React.Dispatch<QuizAction>;
  saveAndContinue: () => Promise<void>;
}

const QuizContext = createContext<QuizContextType | undefined>(undefined);

export const QuizProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { engine } = useAdaptiveEngine();
  const saveInProgress = useRef(false);
  const completedRef = useRef(false);

  const [state, dispatch] = useReducer(quizReducer, {
    ...initialState,
    adaptiveState: engine ? {
      engine,
      currentDifficulty: 5,
      categoryStrengths: {},
      insights: {
        weakCategories: [],
        recommendedDifficulty: 5,
        suggestedPracticeFrequency: 'daily',
        performanceTrend: 'steady',
        timeManagement: {
          averageQuestionTime: 0,
          recommendedTimePerQuestion: 90,
          timeManagementAdvice: 'Take your time'
        }
      },
      recentlyAskedQuestions: [],
      consecutiveCorrect: 0,
      consecutiveIncorrect: 0
    } : null
  });

  useEffect(() => {
    if (state.progress?.completed && !completedRef.current) {
      completedRef.current = true;
      logger.debug('QuizProvider', 'Quiz marked as completed, preventing reset');
    }
  }, [state.progress?.completed]);

  const wrappedDispatch: React.Dispatch<QuizAction> = useCallback((action) => {
    if (completedRef.current && action.type !== 'QUIZ_COMPLETE') {
      logger.warn('QuizProvider', 'Attempted state change after completion', { actionType: action.type });
      return;
    }
    dispatch(action);
  }, []);

  const saveAndContinue = useCallback(async () => {
    // Add save lock to prevent concurrent saves
    if (saveInProgress.current) {
      logger.warn('QuizContext', 'Save already in progress, skipping');
      return;
    }
    
    saveInProgress.current = true;
    
    try {
      logger.debug('QuizContext', 'saveAndContinue called', {
        currentIndex: state.progress?.currentQuestionIndex,
        totalQuestions: state.progress?.questions?.length
      });

      if (!state.progress) {
        logger.warn('QuizContext', 'Cannot continue - missing progress');
        return;
      }

      const currentIndex = state.progress.currentQuestionIndex;
      const questions = state.progress.questions;
      const isLastQuestion = currentIndex === questions.length - 1;

      if (isLastQuestion) {
        logger.debug('QuizContext', 'Processing final question', {
          currentIndex,
          totalQuestions: questions.length
        });

        const correctCount = questions.length - state.progress.wrongAnswers.length;
        const finalScore = (correctCount / questions.length) * 100;
        const summary = getQuizSummary(state.progress);

        // Dispatch completion and IMMEDIATELY return to prevent further processing
        dispatch({
          type: 'QUIZ_COMPLETE',
          payload: {
            finalScore,
            timestamp: Date.now(),
            adaptiveState: state.adaptiveState!,
            summary
          }
        });

        logger.success('QuizContext', 'Quiz completed successfully', { 
          finalScore,
          questionCount: questions.length 
        });
        
        return;
      }

      // Only process next question logic if we haven't completed the quiz
      if (!isLastQuestion && state.adaptiveState?.engine) {
        const currentQuestion = state.progress.questions[currentIndex];
        // Get answer data for current question
        const isCorrect = !state.progress.wrongAnswers.some(wa => wa.question.id === currentQuestion.id);
        const timeSpent = Date.now() - (state.progress.lastQuestionStartTime || 0);

        state.adaptiveState.engine.updateWithResult(currentQuestion, isCorrect, timeSpent);
        
        // Get next question from adaptive engine
        const remainingQuestions = state.progress.questions.slice(currentIndex + 1);
        const nextQuestion = state.adaptiveState.engine.selectNextQuestion(remainingQuestions);
        const nextIndex = currentIndex + 1;

        // Create preserved progress with all required fields
        const preservedProgress: QuizProgress = {
          ...state.progress,
          currentQuestionIndex: nextIndex,
          lastQuestionStartTime: Date.now(),
          date: state.progress.date || new Date().toISOString(), // Keep original creation date
          lastUpdated: new Date().toISOString(), // Track last modification
          quizId: state.progress.quizId,
          initialQuestionIndex: state.progress.initialQuestionIndex,
          score: state.progress.score,
          questions: state.progress.questions,
          questionIds: state.progress.questions.map(q => q.id),
          wrongAnswers: state.progress.wrongAnswers,
          type: state.progress.type || QuizType.SAMPLE,
          category: state.progress.category,
          completed: nextIndex >= state.progress.questions.length - 1,
          adaptiveState: state.progress.adaptiveState
        };

        // Add debug logging
        logger.debug('QuizContext', 'Saving quiz progress', {
          quizId: preservedProgress.quizId,
          currentIndex: preservedProgress.currentQuestionIndex,
          date: preservedProgress.date,
          lastUpdated: preservedProgress.lastUpdated,
          completed: preservedProgress.completed
        });

        // Save first, then dispatch on success
        await saveQuizProgress(preservedProgress);

        dispatch({
          type: 'SAVE_AND_CONTINUE',
          payload: {
            timestamp: Date.now(),
            nextQuestion,
            preservedProgress,
            questionId: nextQuestion.id
          }
        });

        logger.debug('QuizContext', 'Dispatched SAVE_AND_CONTINUE with adaptive selection', {
          nextIndex,
          questionId: nextQuestion.id,
          isCorrect,
          timeSpent
        });
      }
    } catch (error) {
      // Add retry logic for recoverable errors
      if (error instanceof QuizError && error.recoverable) {
        logger.warn('QuizContext', 'Recoverable error in saveAndContinue, will retry', { error });
        // Implement retry logic here
      } else {
        logger.error('QuizContext', 'Error in saveAndContinue', error);
        dispatch({ 
          type: 'SET_ERROR', 
          payload: error instanceof Error ? error : new Error('Failed to save progress') 
        });
      }
    } finally {
      saveInProgress.current = false;
    }
  }, [state.progress, state.adaptiveState?.engine, dispatch]);

  const value = useMemo(() => ({
    state,
    dispatch: wrappedDispatch,
    saveAndContinue
  }), [state, wrappedDispatch, saveAndContinue]);

  return (
    <QuizContext.Provider value={value}>
      {children}
    </QuizContext.Provider>
  );
};

export const useQuizContext = () => {
  const context = useContext(QuizContext);
  if (!context) {
    throw new Error('useQuizContext must be used within a QuizProvider');
  }
  return context;
};