import { LucideIcon } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from './card';

interface DashboardCardProps {
  icon: LucideIcon;
  title: string;
  children: React.ReactNode;
}

export const DashboardCard = ({ icon: Icon, title, children }: DashboardCardProps) => {
  return (
    <Card className="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700">
      <CardHeader>
        <CardTitle className="flex items-center gap-2 text-gray-900 dark:text-gray-100">
          <Icon className="w-5 h-5 text-gray-700 dark:text-gray-300" />
          {title}
        </CardTitle>
      </CardHeader>
      <CardContent>
        {children}
      </CardContent>
    </Card>
  );
}; 