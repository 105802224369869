import { db } from '../../firebase';
import { 
  doc, 
  getDoc, 
  updateDoc, 
  setDoc, 
  deleteField 
} from 'firebase/firestore';
import { 
  TestResult, 
  QuizProgress, 
  QuizType,
  WrongAnswer,
  UserProfile
} from '../quiz/types';
import { getAllActiveQuizzes, saveQuizProgress } from './quiz';

export const migrateQuizData = async (
  userId: string,
  oldData: any
): Promise<{ [key: string]: QuizProgress }> => {
  if (!userId) throw new Error('No user ID provided');

  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    
    if (!userDoc.exists()) {
      console.warn('No user document found for migration');
      return {};
    }

    let migratedQuizzes: { [key: string]: QuizProgress } = {};

    // Handle legacy single quiz progress
    if (oldData?.quizProgress) {
      const legacyQuiz = oldData.quizProgress;
      migratedQuizzes[legacyQuiz.quizId] = {
        quizId: Number(legacyQuiz.quizId),
        type: determineQuizType(legacyQuiz),
        questions: legacyQuiz.questions.map((q: any) => ({
          id: q.id,
          category: q.category || 'Uncategorized',
          question_text: q.question_text || q.Questions,
          option_a: q.option_a || q.A,
          option_b: q.option_b || q.B,
          option_c: q.option_c || q.C,
          option_d: q.option_d || q.D,
          option_e: q.option_e || q.E || '',
          correct_answer: q.correct_answer || q["CORRECT ANSWER"],
          selected_answer: q.selected_answer || null,
          explanation: q.explanation || q.Explanation || '',
          hint: q.hint || q.HINT || ''
        })),
        currentQuestionIndex: legacyQuiz.currentQuestionIndex || 0,
        initialQuestionIndex: legacyQuiz.initialQuestionIndex || 0,
        score: legacyQuiz.score || 0,
        adaptiveState: legacyQuiz.adaptiveState || null,
        date: legacyQuiz.date || new Date().toISOString(),
        wrongAnswers: legacyQuiz.wrongAnswers || [],
        questionIds: legacyQuiz.questionIds || legacyQuiz.questions.map((q: any) => q.id),
        category: legacyQuiz.category,
        completed: (legacyQuiz.currentQuestionIndex || 0) >= (legacyQuiz.questions?.length || 0),
        lastUpdated: legacyQuiz.lastUpdated || new Date().toISOString()
      };
    }

    // Handle multiple quizzes structure
    if (oldData?.quizzes) {
      Object.entries(oldData.quizzes).forEach(([quizId, quiz]: [string, any]) => {
        migratedQuizzes[quizId] = {
          quizId: Number(quizId),
          type: determineQuizType(quiz),
          questions: quiz.questions.map((q: any) => ({
            id: q.id,
            category: q.category || 'Uncategorized',
            question_text: q.question_text || q.Questions,
            option_a: q.option_a || q.A,
            option_b: q.option_b || q.B,
            option_c: q.option_c || q.C,
            option_d: q.option_d || q.D,
            option_e: q.option_e || q.E || '',
            correct_answer: q.correct_answer || q["CORRECT ANSWER"],
            selected_answer: q.selected_answer || null,
            explanation: q.explanation || q.Explanation || '',
            hint: q.hint || q.HINT || ''
          })),
          currentQuestionIndex: quiz.currentQuestionIndex || 0,
          initialQuestionIndex: quiz.initialQuestionIndex || 0,
          score: quiz.score || 0,
          adaptiveState: quiz.adaptiveState || null,
          date: quiz.date || new Date().toISOString(),
          wrongAnswers: quiz.wrongAnswers || [],
          questionIds: quiz.questionIds || quiz.questions.map((q: any) => q.id),
          category: quiz.category,
          completed: (quiz.currentQuestionIndex || 0) >= (quiz.questions?.length || 0),
          lastUpdated: quiz.lastUpdated || new Date().toISOString()
        };
      });
    }

    // Save migrated data back to Firestore
    await updateDoc(userDocRef, {
      quizzes: migratedQuizzes,
      quizProgress: deleteField() // Remove legacy field
    });

    return migratedQuizzes;
  } catch (error) {
    console.error('Error migrating quiz data:', error);
    throw new Error('Failed to migrate quiz data');
  }
};

export const migrateLocalProgress = async (
  userId: string,
  localProgress: QuizProgress
): Promise<void> => {
  if (!userId) throw new Error('No user ID provided');

  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    
    if (!userDoc.exists()) {
      // Create new user document if it doesn't exist
      await setDoc(userDocRef, {
        quizzes: {},
        testResults: []
      });
    }

    // Update with local progress
    await updateDoc(userDocRef, {
      [`quizzes.${localProgress.quizId}`]: {
        ...localProgress,
        lastSynced: new Date().toISOString()
      }
    });
  } catch (error) {
    console.error('Error migrating local progress:', error);
    throw new Error('Failed to migrate local progress');
  }
};

export const determineQuizType = (quiz: Partial<QuizProgress>): QuizType => {
  if (quiz.type) return quiz.type;
  
  // Check if all questions have the same category (Category Quiz)
  if (quiz.questions && quiz.questions.length > 0) {
    const firstCategory = quiz.questions[0].category;
    const isAllSameCategory = quiz.questions.every(
      (q: any) => q.category === firstCategory
    );
    
    if (isAllSameCategory) return QuizType.CATEGORY;
  }
  
  // Sample quizzes are shorter practice sets
  if (quiz.questions && quiz.questions.length <= 35) return QuizType.SAMPLE;
  
  // If neither condition is met, it's a full quiz
  return QuizType.FULL;
};

export const migrateWrongAnswers = async (
    userId: string,
    wrongAnswers: any[]
  ): Promise<void> => {
    if (!userId) throw new Error('No user ID provided');
  
    try {
      const userDocRef = doc(db, 'users', userId);
      
      const migratedWrongAnswers: WrongAnswer[] = wrongAnswers.map(wa => ({
        questionId: wa.question?.id || wa.questionId,
        selectedAnswer: wa.selectedAnswer || wa.question?.selected_answer || '',
        date: wa.date || new Date().toISOString(),
        isReviewed: wa.isReviewed || false,
        question: wa.question || '',
        explanation: wa.explanation || '',
        note: wa.note || '',
        attempts: wa.attempts || 1
      }));
  
      await updateDoc(userDocRef, {
        wrongAnswers: migratedWrongAnswers
      });
    } catch (error) {
      console.error('Error migrating wrong answers:', error);
      throw new Error('Failed to migrate wrong answers');
    }
  };
  
  // Update migrateUserProfile to match UserProfile interface
  export const migrateUserProfile = async (
    userId: string,
    oldProfile: any
  ): Promise<void> => {
    if (!userId) throw new Error('No user ID provided');
  
    try {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      
      if (!userDoc.exists()) {
        throw new Error('User document not found');
      }
  
      const currentProfile = userDoc.data()?.profile || {};
      
      // Map to UserProfile interface
      const migratedProfile: UserProfile = {
        name: oldProfile.name || currentProfile.name || '',
        examDate: oldProfile.examDate || currentProfile.examDate || '',
        studyGoal: oldProfile.studyGoal || currentProfile.studyGoal || '',
        educationLevel: oldProfile.educationLevel || currentProfile.educationLevel,
        preferredStudyTime: oldProfile.preferredStudyTime || currentProfile.preferredStudyTime,
        challengingTopics: oldProfile.challengingTopics || currentProfile.challengingTopics || [],
        learningStyle: oldProfile.learningStyle || currentProfile.learningStyle,
        onboardingCompleted: oldProfile.onboardingCompleted || currentProfile.onboardingCompleted || false,
        lastUpdated: new Date().toISOString()
      };
  
      // Update using same pattern as updateUserProfile
      await updateDoc(userDocRef, {
        profile: {
          ...migratedProfile,
        }
      });
    } catch (error) {
      console.error('Error migrating user profile:', error);
      throw new Error('Failed to migrate user profile');
    }
  };
  
  // Update migrateLocalStorage to be exported and handle errors better
  export const migrateLocalStorage = async (userId: string): Promise<void> => {
    if (!userId) throw new Error('No user ID provided');
  
    try {
      // 1. Quiz Progress
      const quizProgressKeys = Object.keys(localStorage)
        .filter(key => key.startsWith('quiz_progress_'));
      
      for (const key of quizProgressKeys) {
        try {
          const progress = JSON.parse(localStorage.getItem(key) || '{}');
          await migrateQuizData(userId, progress);
          localStorage.removeItem(key);
        } catch (error) {
          console.error(`Error migrating quiz progress for key ${key}:`, error);
          // Continue with other items even if one fails
        }
      }
  
      // 2. Test Results
      try {
        const testResults = JSON.parse(localStorage.getItem('test_results') || '[]');
        await migrateTestResults(userId, testResults);
        localStorage.removeItem('test_results');
      } catch (error) {
        console.error('Error migrating test results:', error);
      }
  
      // 3. Wrong Answers
      try {
        const wrongAnswers = JSON.parse(localStorage.getItem('wrong_answers') || '[]');
        await migrateWrongAnswers(userId, wrongAnswers);
        localStorage.removeItem('wrong_answers');
      } catch (error) {
        console.error('Error migrating wrong answers:', error);
      }
    } catch (error) {
      console.error('Error in migrateLocalStorage:', error);
      throw new Error('Failed to migrate local storage data');
    }
  };

// Helper function to migrate test results
export const migrateTestResults = async (
  userId: string,
  oldResults: any[]
): Promise<void> => {
  if (!userId) throw new Error('No user ID provided');

  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    const existingData = userDoc.data();
    
    // Preserve existing test results
    const existingResults = existingData?.testResults || [];
    
    const migratedResults = oldResults.map(result => ({
      id: result.id,
      date: result.date || new Date().toISOString(),
      score: result.score,
      totalQuestions: result.totalQuestions,
      percentage: result.percentage,
      questions: result.questions, // Keep full question objects
      wrongAnswers: (result.wrongAnswers || []).map((wa: any) => ({
        question: wa.question, // Keep full question object
        explanation: wa.explanation || '',
        note: wa.note || '',
        date: wa.date || new Date().toISOString(),
        isReviewed: wa.isReviewed || false,
        attempts: wa.attempts || 1
      })),
      timeTaken: result.timeTaken,
      adaptiveState: result.adaptiveState || null,
      adaptiveMetrics: result.adaptiveMetrics
    }));

    // Merge with existing results, don't overwrite
    const mergedResults = [...existingResults, ...migratedResults];

    // Update only if we have new data to merge
    if (migratedResults.length > 0) {
      await updateDoc(userDocRef, {
        testResults: mergedResults
      });
    }
  } catch (error) {
    console.error('Error migrating test results:', error);
    throw new Error('Failed to migrate test results');
  }
};

// Helper function to validate migrated data
export const validateMigration = async (
  userId: string
): Promise<boolean> => {
  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    
    if (!userDoc.exists()) return false;
    
    const data = userDoc.data();
    return (
      typeof data.quizzes === 'object' &&
      Array.isArray(data.testResults) &&
      (!data.profile || typeof data.profile === 'object')
    );
  } catch (error) {
    console.error('Error validating migration:', error);
    return false;
  }
};

// Add this function to handle in-progress quizzes
const handleInProgressQuizzes = async (userId: string) => {
  // 1. Check for in-progress quizzes
  const inProgressQuizzes = await getAllActiveQuizzes(userId);
  
  // 2. For each quiz, create a backup
  for (const quiz of inProgressQuizzes) {
    await backupQuizProgress(userId, quiz);
  }

  // 3. Migrate to new format
  const migratedQuizzes = inProgressQuizzes.map(quiz => ({
    ...quiz,
    type: determineQuizType(quiz),
    questionIds: quiz.questions.map(q => q.id),
    version: 2 // Add version tracking
  }));

  // 4. Save migrated quizzes
  for (const quiz of migratedQuizzes) {
    await saveQuizProgress(quiz);
  }
};

// Add backup functionality
const backupQuizProgress = async (
  userId: string, 
  quiz: QuizProgress
): Promise<void> => {
  const userDocRef = doc(db, 'users', userId);
  await updateDoc(userDocRef, {
    [`quizBackups.${quiz.quizId}`]: {
      ...quiz,
      backupDate: new Date().toISOString()
    }
  });
};

export const migrateAllUserData = async (userId: string): Promise<void> => {
    if (!userId) throw new Error('No user ID provided');
  
    try {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      
      if (!userDoc.exists()) {
        console.warn('No user document found for migration');
        return;
      }
  
      const data = userDoc.data();
  
      // 1. Migrate local storage data first
      await migrateLocalStorage(userId);
  
      // 2. Migrate quiz data
      if (data?.quizProgress || data?.quizzes) {
        await migrateQuizData(userId, data);
      }
  
      // 3. Migrate test results
      if (data?.testResults) {
        await migrateTestResults(userId, data.testResults);
      }
  
      // 4. Migrate wrong answers
      if (data?.wrongAnswers) {
        await migrateWrongAnswers(userId, data.wrongAnswers);
      }
  
      // 5. Migrate user profile
      if (data?.profile) {
        await migrateUserProfile(userId, data.profile);
      }
  
      // 6. Validate the migration
      const isValid = await validateMigration(userId);
      if (!isValid) {
        throw new Error('Migration validation failed');
      }
    } catch (error) {
      console.error('Error in migrateAllUserData:', error);
      throw new Error('Failed to migrate all user data');
    }
  };