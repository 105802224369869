import React, { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { QuizContainer } from './components/QuizContainer';
import { useAuth } from './contexts/AuthContext';
import { QuizProvider } from './components/quiz/state/QuizContext';
import { useQuiz } from './hooks/useQuiz';
import QuizSummary from './components/QuizSummary';

const QuizApp: React.FC = () => {
  const { quizId: paramQuizId } = useParams();
  const { userData } = useAuth();
  const { 
    quizProgress, 
    adaptiveEngine, 
    isLoading, 
    error,
    loadExistingQuiz 
  } = useQuiz(Number(paramQuizId));
  const loadedRef = useRef(false);
  const navigate = useNavigate();

  // Only load quiz once
  useEffect(() => {
    if (paramQuizId && !loadedRef.current) {
      loadedRef.current = true;
      loadExistingQuiz();
    }
  }, [paramQuizId, loadExistingQuiz]);

  // Prevent reloading if quiz is completed
  // if (quizProgress?.completed) {
  //   return (
  //     <QuizProvider>
  //       <div className="new-home-container">
  //         <QuizSummary 
  //           quizProgress={quizProgress}
  //           timeSpent={0}
  //           onRestartQuiz={() => navigate('/dashboard')}
  //           showFeedbackButton={true}
  //         />
  //       </div>
  //     </QuizProvider>
  //   );
  // }

  // Show loading state
  if (isLoading) {
    return (
      <div className="new-home-container">
        <div className="new-home-content">
          <div className="new-home-inner">
            <div className="flex items-center justify-center min-h-screen">
              <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Show error state
  if (error || !quizProgress) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <h2 className="text-xl text-red-600 mb-4">
            {error || 'Unable to load quiz'}
          </h2>
          <button 
            onClick={() => window.location.reload()}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  // Only render provider when we have everything ready
  return (
    <QuizProvider>
      <div className="new-home-container">
        <QuizContainer 
          quizId={Number(paramQuizId)}
          initialQuizProgress={quizProgress}
        />
      </div>
    </QuizProvider>
  );
};

export default QuizApp;