import { QuizState, QuizAction, QuizType } from '../../../models/QuizTypes';
import { isQuizComplete, processQuizAnswer, getQuizSummary } from '../../../utils/quiz/core';
import { AdaptiveState } from '../../../models/AdaptiveState';
import { logger } from '../../../utils/logger';

export const initialState: QuizState = {
  progress: null,
  currentQuestion: null,
  showHint: false,
  showResult: false,
  selectedAnswer: null,
  currentExplanation: '',
  isLoading: false,
  needsSave: false,
  error: null,
  errorState: {
    error: null,
    retryCount: 0,
    lastAttempt: 0
  },
  adaptiveState: null,
  questionStartTime: Date.now()
  
};

export const quizReducer = (state: QuizState, action: QuizAction): QuizState => {
  logger.debug('QuizReducer', `Processing ${action.type}`, {
    currentIndex: state.progress?.currentQuestionIndex,
    totalQuestions: state.progress?.questions?.length,
    isCompleted: state.progress?.completed,
    // payload: action.type === 'SET_PROGRESS' ? 'omitted' : action.payload
  });

  switch (action.type) {
    case 'SET_PROGRESS': {
      // Don't override completion state if already completed
      if (state.progress?.completed) {
        logger.debug('QuizReducer', 'Preserving completed state in SET_PROGRESS');
        return state;
      }

      // Add check for duplicate updates
      // if (state.progress?.currentQuestionIndex === action.payload.currentQuestionIndex &&
      //     state.progress?.questions === action.payload.questions) {
      //   logger.debug('QuizReducer', 'Skipping duplicate SET_PROGRESS');
      //   return state;
      // }

      const newProgress = {
        ...action.payload,
        completed: state.progress?.completed || isQuizComplete(action.payload),
        type: action.payload.type || QuizType.SAMPLE
      };

      logger.debug('QuizReducer', 'SET_PROGRESS update', {
        wasCompleted: state.progress?.completed,
        isCompleted: newProgress.completed,
        currentIndex: newProgress.currentQuestionIndex
      });

      return {
        ...state,
        progress: newProgress,
        currentQuestion: action.payload.questions[action.payload.currentQuestionIndex],
        adaptiveState: state.adaptiveState
      };
    }

    case 'HANDLE_ANSWER': {
      logger.debug('QuizReducer', 'HANDLE_ANSWER called', {
        currentIndex: state.progress?.currentQuestionIndex,
        hasProgress: !!state.progress,
        hasCurrentQuestion: !!state.currentQuestion,
        payload: action.payload
      });

      if (!state.progress || !state.currentQuestion) return state;
      
      const { answer, timeSpent } = action.payload;
      
      const { updatedProgress, isCorrect } = processQuizAnswer(
        state.progress,
        state.progress.currentQuestionIndex,
        answer
      );

      // Update adaptive state if exists
      const adaptiveState = state.adaptiveState?.engine 
        ? {
            ...state.adaptiveState,
            currentDifficulty: state.adaptiveState.engine.getCurrentDifficulty(),
            categoryStrengths: state.adaptiveState.engine.getCategoryStrengths(),
            insights: state.adaptiveState.engine.generateInsights(),
          }
        : state.adaptiveState

      return {
        ...state,
        progress: updatedProgress,
        adaptiveState,
        selectedAnswer: answer,
        showResult: true,
        needsSave: true,
        questionStartTime: 0
      };
    }

    case 'INITIALIZE_ADAPTIVE_ENGINE': {
      // console.log('[QuizReducer] Initializing engine:', {
      //   hasEngine: !!action.payload
      // });
      
      return {
        ...state,
        adaptiveState: state.adaptiveState
      };
    }

    case 'UPDATE_ADAPTIVE_STATE': {
      return {
        ...state,
        adaptiveState: state.adaptiveState
      };
    }
    case 'UPDATE_QUESTION_START_TIME': {
      return {
        ...state,
        questionStartTime: action.payload
      };
    }
    case 'NEXT_QUESTION': {
      if (!state.progress) return state;
      const nextIndex = state.progress.currentQuestionIndex + 1;
      
      // Check if we've reached the end of the quiz
      if (nextIndex >= state.progress.questions.length) {
        const summary = getQuizSummary(state.progress);
        const adaptiveState = state.adaptiveState?.engine 
          ? {
              ...state.adaptiveState,
              currentDifficulty: state.adaptiveState.engine?.getCurrentDifficulty(),
              categoryStrengths: state.adaptiveState.engine?.getCategoryStrengths(),
              insights: state.adaptiveState.engine?.generateInsights(),
            } as AdaptiveState
          : state.adaptiveState;


        if (!adaptiveState) {
          logger.warn('QuizReducer', 'No adaptive state found - cannot complete quiz');
          return state;
        }

        return {
          ...state,
          progress: {
            ...state.progress,
            completed: true,
            adaptiveState: adaptiveState
          },
          showResult: true,
          isLoading: false,
          summary,
          needsSave: true
        };
      }
      
      return {
        ...state,
        progress: {
          ...state.progress,
          currentQuestionIndex: nextIndex,
          lastQuestionStartTime: Date.now()
        },
        currentQuestion: state.progress.questions[nextIndex],
        selectedAnswer: null,
        showResult: false,
        showHint: false,
        currentExplanation: '',
        isLoading: false
      };
    }

    case 'PREVIOUS_QUESTION': {
      if (!state.progress || state.progress.currentQuestionIndex <= 0) return state;
      const prevIndex = state.progress.currentQuestionIndex - 1;
      return {
        ...state,
        progress: {
          ...state.progress,
          currentQuestionIndex: prevIndex
        },
        currentQuestion: state.progress.questions[prevIndex],
        selectedAnswer: state.progress.questions[prevIndex].selected_answer || null,
        showResult: !!state.progress.questions[prevIndex].selected_answer,
        showHint: false
      };
    }

    case 'TOGGLE_HINT': {
      return {
        ...state,
        showHint: !state.showHint
      };
    }

    case 'SET_ERROR': {
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    }

    case 'RETRY_SAVE': {
      if (state.errorState.retryCount >= 3) {
        return state;
      }
      return {
        ...state,
        errorState: {
          ...state.errorState,
          retryCount: state.errorState.retryCount + 1,
          lastAttempt: Date.now()
        }
      };
    }

    case 'INCREMENT_RETRY_COUNT': {
      return {
        ...state,
        errorState: {
          ...state.errorState,
          retryCount: state.errorState.retryCount + 1
        }
      };
    }

    case 'RESET_ERROR_STATE': {
      return {
        ...state,
        errorState: {
          error: null,
          retryCount: 0,
          lastAttempt: 0
        }
      };
    }

    case 'SET_SHOW_RESULT': {
      return {
        ...state,
        showResult: action.payload
      };
    }

    case 'SET_EXPLANATION': {
      return {
        ...state,
        currentExplanation: action.payload
      };
    }

    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: action.payload
      };
    }

    case 'SAVE_PROGRESS': {
      return {
        ...state,
        needsSave: false,
        isLoading: false
      };
    }

    case 'SAVE_AND_CONTINUE': {
      const { timestamp, nextQuestion, preservedProgress } = action.payload;
      
      // Don't proceed if quiz is completed
      if (state.progress?.completed) {
        logger.warn('QuizReducer', 'Preventing SAVE_AND_CONTINUE - quiz is completed');
        return state;
      }

      // Always proceed to next question if not completed
      return {
        ...state,
        progress: preservedProgress,
        currentQuestion: nextQuestion,
        selectedAnswer: null,
        showResult: false,
        showHint: false,
        currentExplanation: '',
        questionStartTime: timestamp,
        isLoading: false,
        needsSave: false // Reset needsSave after successful save
      };
    }

    case 'SAVE_ERROR': {
      return {
        ...state,
        error: action.payload,
        needsSave: true
      };
    }

    case 'SET_NEEDS_SAVE': {
      return {
        ...state,
        needsSave: action.payload
      };
    }

    case 'INITIALIZE_ADAPTIVE_ENGINE_WITH_STATE': {
      const { preservedProgress } = action.payload;
      
      // console.log('[Reducer] Initializing engine with preserved state', {
      //   preservedIndex: preservedProgress?.currentQuestionIndex,
      //   currentIndex: state.progress?.currentQuestionIndex
      // });

      return {
        ...state,
        adaptiveState: preservedProgress?.adaptiveState,
        progress: preservedProgress || state.progress
      };
    }

    case 'QUIZ_COMPLETE': {
      const { finalScore, timestamp } = action.payload;
      
      logger.success('QuizReducer', 'Completing quiz', {
        currentIndex: state.progress?.currentQuestionIndex,
        totalQuestions: state.progress?.questions?.length
      });

      if (!state.progress) return state;

      // Get final adaptive state and insights
      let finalAdaptiveState = state.adaptiveState;
      if (state.adaptiveState?.engine) {
        const engine = state.adaptiveState.engine;
        
        // Generate final insights and metrics
        const insights = engine.generateInsights();
        const categoryStrengths = engine.getEnhancedCategoryStrengths();
        const currentDifficulty = engine.getCurrentDifficulty();

        finalAdaptiveState = {
          ...state.adaptiveState,
          currentDifficulty,
          categoryStrengths,
          insights,
          recentlyAskedQuestions: state.progress.questions.map(q => q.id),
          consecutiveCorrect: state.progress.adaptiveState?.consecutiveCorrect || 0, // Reset streaks on quiz completion
          consecutiveIncorrect: state.progress.adaptiveState?.consecutiveIncorrect || 0
        };

        logger.debug('QuizReducer', 'Final adaptive state generated', {
          difficulty: currentDifficulty,
          categoryCount: Object.keys(categoryStrengths).length,
          hasInsights: !!insights
        });
      }

      // Generate quiz summary with adaptive data
      const summary = getQuizSummary(state.progress);

      if (!finalAdaptiveState) {
        logger.warn('QuizReducer', 'No adaptive state found - cannot complete quiz');
        return state;
      }

      // Create final completed state
      const completedState = {
        ...state,
        progress: {
          ...state.progress,
          completed: true,
          score: finalScore,
          lastQuestionStartTime: timestamp,
          adaptiveState: finalAdaptiveState
        },
        adaptiveState: finalAdaptiveState,
        showResult: true,
        isLoading: false,
        summary
      };

      logger.debug('QuizReducer', 'Quiz completed', {
        score: finalScore,
        totalQuestions: state.progress.questions.length,
        hasAdaptiveState: !!finalAdaptiveState
      });

      return completedState;
    }

    case 'SET_ANSWER_WITH_TIME': {
      if (!state.progress || !state.currentQuestion) return state;
      
      const { answer, timeSpent, questionId } = action.payload;
      const { updatedProgress, isCorrect } = processQuizAnswer(
        state.progress,
        state.progress.currentQuestionIndex,
        answer
      );

      // Update adaptive state
      const adaptiveState = state.adaptiveState?.engine 
        ? {
            ...state.adaptiveState,
            currentDifficulty: state.adaptiveState.engine.getCurrentDifficulty(),
            categoryStrengths: state.adaptiveState.engine.getCategoryStrengths(),
            insights: state.adaptiveState.engine.generateInsights(),
          }
        : state.adaptiveState;

      return {
        ...state,
        progress: updatedProgress,
        adaptiveState,
        selectedAnswer: answer,
        showResult: true,
        needsSave: true,
        questionStartTime: Date.now()
      };
    }

    default: {
      return state;
    }
  }
}; 