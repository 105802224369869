export const CACHE_CONFIG = {
  quizzes: {
    ttl: 1000 * 60 * 5, // 5 minutes
    maxSize: 50 // max cached quizzes
  },
  questions: {
    ttl: 1000 * 60 * 60, // 1 hour
    maxSize: 1000 // max cached questions
  }
} as const;

interface CacheConfig {
  ttl: number;
  maxSize: number;
}

interface CacheEntry<T> {
  value: T;
  timestamp: number;
  ttl: number;
}

interface CacheStats {
  hits: number;
  misses: number;
  size: number;
  evictions: number;
}

export class Cache {
  private store = new Map<string, CacheEntry<any>>();
  private maxSize: number;
  private defaultTTL: number;
  private cleanupInterval?: NodeJS.Timeout;
  private stats: CacheStats = {
    hits: 0,
    misses: 0,
    size: 0,
    evictions: 0
  };

  constructor(config: CacheConfig) {
    this.maxSize = config.maxSize;
    this.defaultTTL = config.ttl;
    this.startCleanupInterval();
  }

  private startCleanupInterval(): void {
    this.cleanupInterval = setInterval(() => {
      this.cleanup();
    }, 1000 * 60); // Run cleanup every minute
  }

  private cleanup(): void {
    const now = Date.now();
    this.store.forEach((entry, key) => {
      if (now - entry.timestamp > entry.ttl) {
        this.store.delete(key);
      }
    });
  }

  // Add cleanup on destroy
  destroy(): void {
    if (this.cleanupInterval) {
      clearInterval(this.cleanupInterval);
    }
    this.store.clear();
  }

  getStats(): CacheStats {
    return { ...this.stats };
  }

  async get<T>(key: string): Promise<T | null> {
    if (!key) throw new Error('Cache key cannot be empty');
    
    try {
      const entry = this.store.get(key);
      if (!entry) {
        this.stats.misses++;
        return null;
      }
      this.stats.hits++;

      if (Date.now() - entry.timestamp > entry.ttl) {
        this.store.delete(key);
        return null;
      }

      return entry.value as T;
    } catch (error) {
      console.error('Cache get error:', error);
      return null;
    }
  }

  async set<T>(key: string, value: T, ttl?: number): Promise<void> {
    if (!key) throw new Error('Cache key cannot be empty');
    if (value === undefined) throw new Error('Cache value cannot be undefined');
    
    const actualTTL = ttl || this.defaultTTL;
    if (actualTTL <= 0) throw new Error('TTL must be positive');

    if (this.store.size >= this.maxSize) {
      this.stats.evictions++;
      // Get oldest entry using timestamps
      const oldest = Array.from(this.store.entries()).reduce((a, b) => 
        a[1].timestamp < b[1].timestamp ? a : b
      );
      this.store.delete(oldest[0]);
    }

    this.store.set(key, {
      value,
      timestamp: Date.now(),
      ttl: actualTTL
    });
    this.stats.size = this.store.size;
  }

  async delete(key: string): Promise<void> {
    this.store.delete(key);
  }

  async clear(): Promise<void> {
    this.store.clear();
  }

  // Optional persistence
  async persistToStorage(): Promise<void> {
    const cacheData = Array.from(this.store.entries());
    try {
      localStorage.setItem('cache', JSON.stringify(cacheData));
    } catch (error) {
      console.error('Failed to persist cache:', error);
    }
  }

  async loadFromStorage(): Promise<void> {
    try {
      const data = localStorage.getItem('cache');
      if (data) {
        const cacheData = JSON.parse(data);
        this.store = new Map(cacheData);
      }
    } catch (error) {
      console.error('Failed to load cache:', error);
    }
  }
}

export const quizCache = new Cache(CACHE_CONFIG.quizzes);
export const questionCache = new Cache(CACHE_CONFIG.questions);
