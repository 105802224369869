export class QuizError extends Error {
  constructor(
    message: string,
    public code: string,
    public recoverable: boolean = true
  ) {
    super(message);
    this.name = 'QuizError';
  }
}

export const QuizErrorCodes = {
  SAVE_FAILED: 'QUIZ_SAVE_FAILED',
  STATE_INCONSISTENT: 'QUIZ_STATE_INCONSISTENT',
  PROGRESS_CORRUPTED: 'QUIZ_PROGRESS_CORRUPTED',
  NETWORK_ERROR: 'QUIZ_NETWORK_ERROR',
  INVALID_DATA: 'INVALID_DATA',
  CONCURRENT_UPDATE: 'CONCURRENT_UPDATE'
} as const; 