import React, { createContext, useContext, useRef, useMemo, useCallback, useEffect, useState } from 'react';
import { AdaptiveLearningEngine } from '../utils/adaptiveLearning';
import { logger } from '../utils/logger';
import { useAuth } from './AuthContext';

interface AdaptiveEngineContextType {
  engine: AdaptiveLearningEngine | null;
  engineId: string | null;
  resetEngine: () => void;
  initializeEngine: (userData: any) => void;
}

const defaultResetEngine = (): void => {
  logger.debug('AdaptiveEngineContext', 'Default resetEngine called - no provider');
};

const defaultInitializeEngine = (userData: any): void => {
  logger.debug('AdaptiveEngineContext', 'Default initializeEngine called - no provider');
};

const AdaptiveEngineContext = createContext<AdaptiveEngineContextType>({
  engine: null,
  engineId: null,
  resetEngine: defaultResetEngine,
  initializeEngine: defaultInitializeEngine
});

export const AdaptiveEngineProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { userData } = useAuth();
  const engineRef = useRef<AdaptiveLearningEngine | null>(null);
  const engineIdRef = useRef<string | null>(null);
  const [engineVersion, setEngineVersion] = useState(0);

  useEffect(() => {
    if (userData?.profile && !engineRef.current) {
      logger.info('AdaptiveEngineContext', 'Auto-initializing engine with userData', {
        hasProfile: !!userData.profile,
        hasTestResults: !!userData.testResults,
        hasWrongAnswers: !!userData.totalWrongAnswers
      });
      
      initializeEngine(userData);
    }
  }, [userData]);

  const initializeEngine = useCallback((userData: any) => {
    if (!userData?.profile) {
      logger.warn('AdaptiveEngineContext', 'Cannot initialize engine without user profile');
      return;
    }

    logger.info('AdaptiveEngineContext', 'Initializing engine');
    engineRef.current = new AdaptiveLearningEngine(
      userData.profile,
      userData.testResults ?? {},
      userData.totalWrongAnswers ?? []
    );
    engineIdRef.current = `engine_${Date.now()}`;
    setEngineVersion(v => v + 1);
  }, []);

  const resetEngine = useCallback(() => {
    engineRef.current = null;
    engineIdRef.current = null;
    setEngineVersion(v => v + 1);
    logger.debug('AdaptiveEngineContext', 'Engine reset');
  }, []);

  const value = useMemo(() => ({
    engine: engineRef.current,
    engineId: engineIdRef.current,
    resetEngine,
    initializeEngine
  }), [resetEngine, initializeEngine, engineVersion]);

  useEffect(() => {
    logger.debug('AdaptiveEngineContext', 'Provider state update', {
      hasEngine: !!engineRef.current,
      engineId: engineIdRef.current,
      engineVersion,
      hasUserData: !!userData,
      hasUserProfile: !!userData?.profile
    });
  }, [userData, engineVersion]);

  return (
    <AdaptiveEngineContext.Provider value={value}>
      {children}
    </AdaptiveEngineContext.Provider>
  );
};

export const useAdaptiveEngine = () => {
  const context = useContext(AdaptiveEngineContext);
  if (!context) {
    throw new Error('useAdaptiveEngine must be used within AdaptiveEngineProvider');
  }
  return context;
}; 