import { db, auth } from '../../firebase';
import { 
  doc, 
  getDoc, 
  updateDoc, 
  collection, 
  query, 
  where, 
  getDocs, 
  deleteField, 
  limit, 
  QueryConstraint,
  addDoc,
  runTransaction,
  FirestoreError
} from 'firebase/firestore';
import { 
  Question,
  QuizProgress, 
  QuizType,
  QuizFetchParams 
} from '../quiz/types';
import { sanitizeQuestion } from '../quiz/validation';
import { migrateQuizData, determineQuizType } from './migration';
import typedQuestionsData from '../questions.json';
import { QuizError, QuizErrorCodes } from '../quiz/errors';
import { quizCache, questionCache } from './cache';
import { CACHE_CONFIG } from './cache';
import { logger } from '../logger';

interface CacheKeyGenerators {
  questions: (params: QuizFetchParams) => string;
  quiz: (userId: string, quizId: number) => string;
  activeQuizzes: (userId: string) => string;
}

const generateCacheKey: CacheKeyGenerators = {
  questions: (params: QuizFetchParams): string => 
    `questions:${params.type}:${params.category ?? 'all'}:${params.questionIds?.join(',') ?? 'none'}`,
    
  quiz: (userId: string, quizId: number): string => 
    `quiz:${userId}:${quizId}`,
    
  activeQuizzes: (userId: string): string => 
    `activeQuizzes:${userId}`
};

interface QuizData {
  quizzes?: Record<string, Partial<QuizProgress>>;
  quizProgress?: any; // Define proper type if known
}

// Core quiz fetching function
export const fetchQuestions = async (params: QuizFetchParams): Promise<Question[]> => {
  const cacheKey = generateCacheKey.questions(params);
  
  try {
    // Try cache first
    const cachedQuestions = await questionCache.get<Question[]>(cacheKey);
    if (cachedQuestions) {
      logger.debug(`Cached questions:`, `${cachedQuestions.length}`);
      return cachedQuestions;
    }

    // Fetch fresh data
    let questions: Question[];
    if (params.type === QuizType.SAMPLE) {
      questions = shuffleArray(typedQuestionsData.map(sanitizeQuestion));
    } else {
      const questionsRef = collection(db, 'questions');
      const constraints = buildQueryConstraints(params);
      const querySnapshot = await getDocs(query(questionsRef, ...constraints));
      
      if (querySnapshot.empty) {
        return [];
      }

      questions = querySnapshot.docs.map(doc => 
        sanitizeQuestion({
          ...doc.data() as Question,
          id: doc.id
        })
      );
    }

    // Cache the results
    await questionCache.set(cacheKey, questions, CACHE_CONFIG.questions.ttl);
    return questions;
  } catch (error) {
    console.error('Error in fetchQuestions:', error);
    throw error;
  }
};

// Quiz progress management
export const saveQuizProgress = async (progress: QuizProgress): Promise<void> => {
  const user = auth.currentUser;
  if (!user) throw new QuizError('No authenticated user', QuizErrorCodes.SAVE_FAILED, false);

  logger.debug('Quiz', 'Starting quiz save transaction', {
    quizId: progress.quizId,
    userId: user.uid,
    currentIndex: progress.currentQuestionIndex
  });

  try {
    // Log before transaction
    logger.debug('Quiz', 'Beginning transaction');
    
    const result = await runTransaction(db, async (transaction) => {
      logger.debug('Quiz', 'Inside transaction');
      
      const userDocRef = doc(db, 'users', user.uid);
      
      const userDoc = await transaction.get(userDocRef);
      if (!userDoc.exists()) {
        logger.error('Quiz', 'User document not found in transaction');
        throw new Error('User document not found');
      }

      logger.debug('Quiz', 'Retrieved user doc in transaction', {
        exists: userDoc.exists(),
        hasData: !!userDoc.data()
      });

      // Migrate/sanitize the progress data first
      const migratedProgress = migrateQuizProgress(progress);
      
      // Add version check to prevent concurrent updates
      const existingData = userDoc.data() as QuizData;
      const existingQuiz = existingData?.quizzes?.[migratedProgress.quizId];
      
      logger.debug('Quiz', 'Checking existing quiz data', {
        hasExistingQuiz: !!existingQuiz,
        existingLastUpdated: existingQuiz?.lastUpdated,
        newLastUpdated: migratedProgress.lastUpdated
      });

      if (existingQuiz && existingQuiz.lastUpdated && 
          new Date(existingQuiz.lastUpdated) > new Date(migratedProgress.lastUpdated)) {
        throw new QuizError(
          'Quiz data was updated elsewhere',
          QuizErrorCodes.CONCURRENT_UPDATE,
          true
        );
      }

      const sanitizedProgress = {
        ...migratedProgress,
        type: migratedProgress.type || QuizType.SAMPLE,
        questionIds: migratedProgress.questionIds || migratedProgress.questions.map(q => q.id),
        lastUpdated: new Date().toISOString(),
        completed: migratedProgress.currentQuestionIndex >= migratedProgress.questions.length - 1,
        adaptiveState: migratedProgress.adaptiveState ? {
          currentDifficulty: migratedProgress.adaptiveState.currentDifficulty,
          categoryStrengths: Object.fromEntries(
            Object.entries(migratedProgress.adaptiveState.categoryStrengths)
              .map(([category, data]) => [
                category,
                {
                  correctCount: data.correctCount,
                  totalCount: data.totalCount
                }
              ])
          ),
          insights: migratedProgress.adaptiveState.insights
        } : undefined
      };

      const updates: Record<string, any> = {
        [`quizzes.${migratedProgress.quizId}`]: sanitizedProgress,
        quizProgress: deleteField()
      };

      logger.debug('Quiz', 'Preparing to update with transaction', {
        path: `users/${user.uid}`,
        quizId: migratedProgress.quizId,
        hasUpdates: !!updates
      });

      // Actually perform the update
      transaction.update(userDocRef, updates);
      
      logger.debug('Quiz', 'Transaction update called');
      
      // Return something to indicate success
      return { success: true };
    });

    // Log after transaction
    logger.debug('Quiz', 'Transaction completed', {
      result,
      quizId: progress.quizId
    });

  } catch (error) {
    logger.error('Quiz', 'Transaction failed', {
      error,
      errorMessage: error instanceof Error ? error.message : 'Unknown error',
      errorCode: error instanceof FirestoreError ? error.code : 'unknown',
      quizId: progress.quizId,
      userId: user.uid
    });

    // Handle specific Firebase errors
    if (error instanceof FirestoreError) {
      switch (error.code) {
        case 'unavailable':
        case 'resource-exhausted':
        case 'deadline-exceeded':
          throw new QuizError(
            'Network error while saving progress',
            QuizErrorCodes.NETWORK_ERROR,
            true
          );
          
        case 'failed-precondition':
          throw new QuizError(
            'Quiz update failed - data in invalid state',
            QuizErrorCodes.STATE_INCONSISTENT,
            false
          );
          
        case 'permission-denied':
          throw new QuizError(
            'User does not have permission to update quiz',
            QuizErrorCodes.SAVE_FAILED,
            false
          );
      }
    }

    // Network error handling
    if (error instanceof Error && (
      error.message.toLowerCase().includes('network') ||
      error.message.toLowerCase().includes('timeout') ||
      error.message.toLowerCase().includes('connection')
    )) {
      throw new QuizError(
        'Network error while saving progress',
        QuizErrorCodes.NETWORK_ERROR,
        true
      );
    }

    // Generic error
    throw new QuizError(
      'Failed to save quiz progress',
      QuizErrorCodes.SAVE_FAILED,
      false
    );
  }
};

// Helper functions
const buildQueryConstraints = ({
  type,
  category,
  questionIds,
  isSubscribed
}: QuizFetchParams): QueryConstraint[] => {
  const constraints: QueryConstraint[] = [];

  switch (type) {
    case QuizType.CATEGORY:
      if (!category) throw new Error('Category required for category quiz');
      constraints.push(
        where('category', '==', category),
        limit(50)
      );
      break;
    case QuizType.FULL:
      if (!isSubscribed) throw new Error('Full quiz requires subscription');
      constraints.push(limit(170));
      break;
    default:
      constraints.push(limit(15));
  }

  if (questionIds?.length) {
    constraints.push(where('id', 'in', questionIds));
  }

  return constraints;
};

export const getActiveQuiz = async (
  quizId: number
): Promise<QuizProgress | null> => {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const cacheKey = generateCacheKey.quiz(user.uid, quizId);
  
  try {
    // Try cache first
    const cachedQuiz = await quizCache.get<QuizProgress>(cacheKey);
    if (cachedQuiz) {
      return cachedQuiz;
    }

    // Fetch fresh data
    const userDocRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data() as QuizData | undefined;

    let quiz: QuizProgress | null = null;

    if (data?.quizzes?.[quizId]) {
      quiz = {
        ...data.quizzes[quizId],
        type: data.quizzes[quizId].type ?? determineQuizType(data.quizzes[quizId])
      } as QuizProgress;
    } else if (data?.quizProgress) {
      const migratedData = await migrateQuizData(user.uid, data);
      quiz = migratedData[quizId] as QuizProgress || null;
    }

    // Cache if found
    if (quiz) {
      await quizCache.set(cacheKey, quiz, CACHE_CONFIG.quizzes.ttl);
    }
    return quiz;
  } catch (error) {
    console.error('Error getting active quiz:', error);
    throw error;
  }
};

export const getAllActiveQuizzes = async (
  userId: string
): Promise<QuizProgress[]> => {
  if (!userId) throw new Error('No user ID provided');

  const cacheKey = generateCacheKey.activeQuizzes(userId);
  
  try {
    // Remove cache check temporarily to debug
    // const cachedQuizzes = await quizCache.get<QuizProgress[]>(cacheKey);
    // if (cachedQuizzes) {
    //   return cachedQuizzes;
    // }

    // Fetch fresh data
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    const data = userDoc.data() as QuizData | undefined;

    logger.debug('Quiz', 'Fetching active quizzes', {
      userId,
      hasData: !!data,
      hasQuizzes: !!data?.quizzes,
      quizCount: data?.quizzes ? Object.keys(data.quizzes).length : 0
    });

    let quizzes = data?.quizzes;

    if (!quizzes && data?.quizProgress) {
      const migratedData = await migrateQuizData(userId, data);
      quizzes = migratedData;
    }

    if (!quizzes) {
      return [];
    }

    const activeQuizzes = Object.entries(quizzes)
      .map(([quizId, quiz]) => {
        const typedQuiz = quiz as Partial<QuizProgress>;
        const completed = (typedQuiz.currentQuestionIndex ?? 0) >= (typedQuiz.questions?.length ?? 0);
        
        return {
          quizId: Number(quizId),
          currentQuestionIndex: typedQuiz.currentQuestionIndex ?? 0,
          initialQuestionIndex: typedQuiz.initialQuestionIndex ?? 0,
          score: typedQuiz.score ?? 0,
          date: typedQuiz.date ?? new Date().toISOString(),
          lastUpdated: typedQuiz.lastUpdated ?? new Date().toISOString(),
          wrongAnswers: typedQuiz.wrongAnswers ?? [],
          questions: typedQuiz.questions ?? [],
          questionIds: typedQuiz.questionIds ?? typedQuiz.questions?.map(q => q.id) ?? [],
          type: typedQuiz.type ?? determineQuizType(typedQuiz),
          category: typedQuiz.category,
          completed,
          adaptiveState: typedQuiz.adaptiveState
        } as QuizProgress;
      })
      .filter(quiz => !quiz.completed);

    logger.debug('Quiz', 'Processed active quizzes', {
      totalQuizzes: Object.keys(quizzes).length,
      activeQuizzes: activeQuizzes.length,
      firstQuizProgress: activeQuizzes[0] ? {
        currentIndex: activeQuizzes[0].currentQuestionIndex,
        total: activeQuizzes[0].questions.length
      } : null
    });

    // Update cache with fresh data
    await quizCache.set(cacheKey, activeQuizzes, 0); // Set TTL to 0 to effectively disable caching
    return activeQuizzes;
  } catch (error) {
    logger.error('Quiz', 'Error getting active quizzes', {
      error,
      userId
    });
    throw error;
  }
};

// Helper function to shuffle array
const shuffleArray = <T,>(array: T[]): T[] => {
    return [...array].sort(() => Math.random() - 0.5);
  };
  
const migrateQuizProgress = (progress: Partial<QuizProgress>): QuizProgress => {
  const now = new Date().toISOString();
  return {
    ...progress,
    date: progress.date || now, // Original creation date
    lastUpdated: progress.lastUpdated || now, // Last modification time
    type: progress.type || QuizType.SAMPLE,
    completed: progress.completed || false,
    category: progress.category || 'unknown',
    questionIds: progress.questionIds || progress.questions?.map(q => q.id) || [],
    initialQuestionIndex: progress.initialQuestionIndex || 0,
    score: progress.score || 0,
    wrongAnswers: progress.wrongAnswers || [],
    adaptiveState: progress.adaptiveState || {
      currentDifficulty: 5,
      categoryStrengths: {},
      insights: {
        weakCategories: [],
        recommendedDifficulty: 5,
        suggestedPracticeFrequency: 'daily',
        performanceTrend: 'steady',
        timeManagement: {
          averageQuestionTime: 0,
          recommendedTimePerQuestion: 90,
          timeManagementAdvice: 'Take your time'
        }
      }
    }
  } as QuizProgress;
};
  