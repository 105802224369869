import { memo, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { logger } from '../../utils/logger';
import { QuizType, WrongAnswer, User } from '../../models/UserTypes';
import { XIcon, ClockIcon, LucideMessageCircleQuestion } from 'lucide-react';
import { QuizProgress } from '../../utils/quiz/types';
import { ASWB_CATEGORIES, QUIZ_DETAILS } from './constants';
        
export type StudyOptionsProps = {
    userData: User | null;
    wrongAnswers: WrongAnswer[];
    initializeQuizWithParams: (params: {
        type: QuizType;
        category?: string;
        adaptiveEnabled?: boolean;
    }) => Promise<QuizProgress>;
};

const StudyOptionsComponent = memo(function StudyOptionsComponent({ 
    userData, 
    wrongAnswers, 
    initializeQuizWithParams 
}: StudyOptionsProps) {
    const navigate = useNavigate();
    const [state, setState] = useState({
        showConfirmation: false,
        selectedQuiz: null as QuizType | null
    });

    const handleQuizSelect = useCallback((type: QuizType) => {
        logger.info('Dashboard', 'Quiz type selected', {
            quizType: type,
            isSubscribed: userData?.isSubscribed,
            totalQuizzesTaken: userData?.testResults?.length || 0
        });
        
        setState({
            showConfirmation: true,
            selectedQuiz: type
        });
    }, [userData]);

    const handleConfirm = useCallback(async (category?: string) => {
        logger.info('Dashboard', 'Starting quiz creation', {
            selectedQuiz: state.selectedQuiz,
            category,
            hasInitFunction: !!initializeQuizWithParams,
            userData: {
                hasProfile: !!userData?.profile,
                testResults: userData?.testResults,
                wrongAnswers: wrongAnswers.length
            }
        });

        try {
            const progress = await initializeQuizWithParams({
                type: state.selectedQuiz ?? QuizType.SAMPLE,
                category: category,
                adaptiveEnabled: true
            });
            
            navigate(`/quiz/${progress.quizId}`);
        } catch (error) {
            logger.error('Dashboard', 'Quiz creation failed', error);
        }
        setState(prev => ({ ...prev, showConfirmation: false }));
    }, [state.selectedQuiz, userData, wrongAnswers, initializeQuizWithParams, navigate]);

    return (
        <>
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 col-span-full">
                <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
                    Study Options
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {/* Category Practice */}
                    <button
                        onClick={() => handleQuizSelect(QuizType.CATEGORY)}
                        className="p-6 rounded-lg border-2 border-blue-200 dark:border-blue-800 hover:border-blue-300 dark:hover:border-blue-700 transition-all hover:shadow-md"
                    >
                        <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-100">
                            Category Practice
                        </h3>
                        <p className="text-sm text-gray-600 dark:text-gray-400">
                            Focus on specific ASWB exam topics to strengthen your weak areas
                        </p>
                    </button>

                    {/* Full Length Test */}
                    <button
                        onClick={() => handleQuizSelect(QuizType.FULL)}
                        className="p-6 rounded-lg border-2 border-green-200 dark:border-green-800 hover:border-green-300 dark:hover:border-green-700 transition-all hover:shadow-md"
                    >
                        <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-100">
                            Full Length Test
                        </h3>
                        <p className="text-sm text-gray-600 dark:text-gray-400">
                            170 questions, timed practice exam simulating real test conditions
                        </p>
                    </button>

                    {/* Quick Quiz */}
                    <button
                        onClick={() => handleQuizSelect(QuizType.SAMPLE)}
                        className="p-6 rounded-lg border-2 border-purple-200 dark:border-purple-800 hover:border-purple-300 dark:hover:border-purple-700 transition-all hover:shadow-md"
                    >
                        <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-100">
                            Quick Quiz
                        </h3>
                        <p className="text-sm text-gray-600 dark:text-gray-400">
                            30 questions from mixed categories for rapid practice
                        </p>
                    </button>
                </div>
            </div>

            {state.showConfirmation && (
                <QuizConfirmationModal
                    quizType={state.selectedQuiz!}
                    onConfirm={handleConfirm}
                    onClose={() => setState(prev => ({ ...prev, showConfirmation: false }))}
                />
            )}
        </>
    );
}, (prevProps, nextProps) => {
    return (
        prevProps.userData?.id === nextProps.userData?.id &&
        prevProps.wrongAnswers.length === nextProps.wrongAnswers.length &&
        prevProps.initializeQuizWithParams === nextProps.initializeQuizWithParams
    );
});

const QuizConfirmationModal = memo(function QuizConfirmationModal({ 
    quizType, 
    onConfirm, 
    onClose 
}: { 
    quizType: string;
    onConfirm: (category?: string) => void; 
    onClose: () => void; 
}) {
    const details = QUIZ_DETAILS[quizType];
    const [selectedCategory, setSelectedCategory] = useState<string>('');

    return (
        <div className="fixed inset-0 bg-black/50 dark:bg-black/70 flex items-center justify-center p-4 z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg max-w-lg w-full p-6 shadow-xl">
                <div className="flex justify-between items-start mb-4">
                    <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100">
                        {details.title}
                        {selectedCategory && (
                            <span className="block text-sm text-blue-600 dark:text-blue-400 mt-1">
                                {selectedCategory}
                            </span>
                        )}
                    </h3>
                    <button 
                        onClick={onClose} 
                        className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                    >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" />
                    </button>
                </div>

                <div className="space-y-4">
                    {quizType === 'category' && (
                        <div className="mb-4">
                            <label 
                                htmlFor="category" 
                                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                            >
                                Select Category
                            </label>
                            <select
                                id="category"
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                className="w-full rounded-lg border border-gray-300 dark:border-gray-600 
                                         bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 
                                         px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500
                                         dark:focus:ring-blue-400"
                                required
                            >
                                <option value="" disabled>Choose a category...</option>
                                {ASWB_CATEGORIES.map((category) => (
                                    <option 
                                        key={category} 
                                        value={category}
                                        className="bg-white dark:bg-gray-700"
                                    >
                                        {category}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    <p className="text-gray-600 dark:text-gray-400">{details.description}</p>
                    
                    <div className="flex space-x-4 text-sm text-gray-600 dark:text-gray-400">
                        <div className="flex items-center">
                            <ClockIcon className="h-4 w-4 mr-1" />
                            {details.duration}
                        </div>
                        {details.questionCount && (
                            <div className="flex items-center">
                                <LucideMessageCircleQuestion className="h-4 w-4 mr-1" />
                                {details.questionCount} questions
                            </div>
                        )}
                    </div>

                    <div className="border-t border-gray-200 dark:border-gray-700 pt-4">
                        <h4 className="font-semibold mb-2 text-gray-800 dark:text-gray-200">Features:</h4>
                        <ul className="list-disc list-inside space-y-1 text-sm text-gray-600 dark:text-gray-400">
                            {details.features.map((feature, index) => (
                                <li key={index}>{feature}</li>
                            ))}
                        </ul>
                    </div>

                    <div className="flex space-x-3 mt-6">
                        <button
                            onClick={() => onConfirm(selectedCategory)}
                            disabled={quizType === 'category' && !selectedCategory}
                            className={`flex-1 px-4 py-2 rounded-lg transition-colors
                                ${quizType === 'category' && !selectedCategory 
                                    ? 'bg-gray-400 dark:bg-gray-600 cursor-not-allowed' 
                                    : 'bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600'} 
                                text-white`}
                        >
                            Start Quiz
                        </button>
                        <button
                            onClick={onClose}
                            className="flex-1 border border-gray-300 dark:border-gray-600 
                                     px-4 py-2 rounded-lg 
                                     text-gray-700 dark:text-gray-300
                                     hover:bg-gray-50 dark:hover:bg-gray-700
                                     transition-colors"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default StudyOptionsComponent;