import React, { useState, useEffect, useMemo } from 'react';
import { DashboardCard } from './ui/dashboard-card';
import { Trophy, Clock, Target, Brain } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { logger } from '../utils/logger';
import { saveTestResult } from '../utils/firebase/testResults';
import { useAuth } from '../contexts/AuthContext';
import { QuizProgress } from '../utils/quiz/types';
import { prepareTestResult } from '../utils/quiz/core';
import { toStorageState } from '../models/AdaptiveState';

interface QuizSummaryProps {
  quizProgress: QuizProgress;
  timeSpent: number;
  onRestartQuiz: () => void;
  showFeedbackButton?: boolean;
}

const QuizSummary: React.FC<QuizSummaryProps> = ({
  quizProgress,
  timeSpent,
  onRestartQuiz
}) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [saveComplete, setSaveComplete] = useState(false);

  // Safely calculate metrics with proper type checking
  const metrics = useMemo(() => {
    if (!quizProgress?.adaptiveState) {
      logger.error('QuizSummary', 'Missing adaptive state');
      return null;
    }

    logger.debug('QuizSummary', 'Raw quiz progress', {
      questions: quizProgress.questions.length,
      wrongAnswers: quizProgress.wrongAnswers,
      adaptiveState: quizProgress.adaptiveState
    });

    // Calculate correct answers directly from questions
    const correctAnswers = quizProgress.questions.filter(q => 
      q.selected_answer === q.correct_answer
    ).length;

    const totalQuestions = quizProgress.questions.length;
    
    const overallScore = totalQuestions > 0 
      ? Math.round((correctAnswers / totalQuestions) * 100)
      : 0;

    // Use stored insights instead of computing
    const { averageQuestionTime, timeManagementAdvice } = 
      quizProgress.adaptiveState.insights.timeManagement;

    return {
      totalQuestions,
      correctAnswers,
      overallScore,
      averageTimePerQuestion: Math.round(averageQuestionTime),
      timeManagementAdvice
    };
  }, [quizProgress]);

  // Render category strengths from stored state
  const renderCategoryStrengths = () => {
    if (!quizProgress?.adaptiveState?.categoryStrengths) return null;

    const sortedCategories = Object.entries(quizProgress.adaptiveState.categoryStrengths)
      .filter(([_, stats]) => stats.totalCount > 0)
      .map(([category, stats]) => ({
        category,
        successRate: stats.correctCount / stats.totalCount
      }))
      .sort((a, b) => b.successRate - a.successRate)
      .slice(0, 3);

    return (
      <DashboardCard icon={Brain} title="Strongest Categories">
        <div className="space-y-2">
          {sortedCategories.map(({ category, successRate }, index) => (
            <div key={index} className="flex justify-between items-center">
              <span className="truncate">{category}</span>
              <span className="font-semibold text-green-500">
                {Math.round(successRate * 100)}%
              </span>
            </div>
          ))}
        </div>
      </DashboardCard>
    );
  };

  useEffect(() => {
    const saveResults = async () => {
      if (!currentUser || !quizProgress?.adaptiveState || !metrics) {
        logger.warn('QuizSummary', 'Missing required data for saving', {
          hasUser: !!currentUser,
          hasAdaptiveState: !!quizProgress?.adaptiveState,
          hasMetrics: !!metrics
        });
        return;
      }

      if (saveComplete) {
        logger.debug('QuizSummary', 'Results already saved');
        return;
      }

      setIsSaving(true);
      setError(null);

      try {
        logger.info('QuizSummary', 'Preparing to save quiz results', {
          userId: currentUser.uid,
          metrics
        });

        const testResult = prepareTestResult(quizProgress);
        const serializableState = toStorageState(quizProgress.adaptiveState);
        
        await saveTestResult(
          currentUser.uid,
          testResult,
          serializableState
        );

        setSaveComplete(true);
        logger.success('QuizSummary', 'Quiz results saved successfully');
      } catch (err) {
        const errorMessage = 'Failed to save quiz results';
        logger.error('QuizSummary', errorMessage, err);
        setError(errorMessage);
      } finally {
        setIsSaving(false);
      }
    };

    saveResults();
  }, [currentUser, quizProgress, metrics, saveComplete]);

  if (!metrics) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto space-y-6 p-4">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          {error}
        </div>
      )}

      <h2 className="text-2xl font-bold text-center mb-8">Quiz Complete!</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <DashboardCard icon={Trophy} title="Overall Performance">
          <div className="text-center">
            <div className="text-4xl font-bold mb-2">{metrics.overallScore}%</div>
            <p className="text-sm text-gray-500">
              {metrics.correctAnswers} correct out of {metrics.totalQuestions} questions
            </p>
          </div>
        </DashboardCard>

        <DashboardCard icon={Clock} title="Time Analysis">
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <span className="font-semibold">{metrics.timeManagementAdvice}</span>
            </div>
            <div className="flex justify-between items-center">
              <span>Avg. Time per Question:</span>
              <span className="font-semibold">
                {timeSpent} seconds
              </span>
            </div>
          </div>
        </DashboardCard>

        {renderCategoryStrengths()}
        
        {quizProgress.adaptiveState.insights.weakCategories.length > 0 && (
          <DashboardCard icon={Target} title="Areas for Improvement">
            <div className="space-y-2">
            {quizProgress.adaptiveState.insights.weakCategories
              .slice(0, 3)
              .map((category, index) => (
                <div key={index} className="flex justify-between items-center">
                  <span className="truncate">{category.category}</span>
                  <span className="font-semibold text-red-500">
                    {Math.round(category.successRate * 100)}%
                  </span>
                </div>
              ))}
          </div>
        </DashboardCard>
      )}
      </div>

      <div className="flex justify-center gap-4 mt-8">
        <button
          onClick={onRestartQuiz}
          className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 
                   transition-colors font-semibold"
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Start New Quiz'}
        </button>
        <button 
          onClick={() => navigate('/feedback')}
          className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 
                   transition-colors font-semibold"
          disabled={isSaving}
        >
          Give Us Feedback
        </button>
      </div>
    </div>
  );
};

export default QuizSummary; 